import { Injectable } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserRoles } from '../core.types';
import { AuthenticationService } from './authentication.service';
import { Logger } from './logger.service';
import { UserAccountService } from './user-accounts.service';

@Injectable({
  providedIn: 'root',
})
export class AccountUtilsService {
  private readonly log = new Logger(this.constructor.name);

  constructor(
    private authService: AuthenticationService,
    private account: UserAccountService
  ) {}

  getRoleCountries$(): Observable<string[]> {
    this.log.debug('get role countries');
    return combineLatest([
      this.authService.getUserRoles$(),
      this.account.getUserMetadata(this.authService.getUserId()),
    ]).pipe(
      map(([roles, userMetaData]) =>
        roles &&
        (roles.indexOf(UserRoles.local) > -1 ||
          roles.indexOf(UserRoles.accountantManager) > -1) &&
        userMetaData &&
        userMetaData.countries &&
        userMetaData.countries.length &&
        userMetaData.countries.indexOf('*') < 0
          ? userMetaData.countries
              .filter((c) => !!c)
              .map((c) => c.toLowerCase())
          : null
      )
    );
  }
}
