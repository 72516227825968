import { Bundle, Product } from '../../core.types';

export class SetProductsAction {
  static type = '[Products] SetProductsAction';
  constructor(public products: Product[]) {}
}
export class SetBundlesAction {
  static type = '[Products] SetBundlesAction';
  constructor(public bundles: Bundle[]) {}
}

export class LoadProductsAction {
  static type = '[Products] LoadProductsAction';
}

export class LoadProductAction {
  static type = '[Products] LoadProductAction';
  constructor(public id: string) {}
}

export class AddProductAction {
  static type = '[Products] AddProduct';
  constructor(
    public product: Product,
    public reload?: boolean
  ) {}
}

export class UpdateProductAction {
  static type = '[Products] Update';
  constructor(
    public product: Product,
    public reload?: boolean
  ) {}
}

export class AddChildProducts {
  static type = '[Products] AddChildProducts';
  constructor(
    public productId: string,
    public childProductIds: string[]
  ) {}
}

export class RemoveChildProducts {
  static type = '[Products] RemoveChildProducts';
  constructor(
    public productId: string,
    public childProductIds: string[]
  ) {}
}

export class DeactivateProductAction {
  static type = '[Products] DeactivateProduct';
  constructor(public productId: string) {}
}

export class ReactivateProductAction {
  static type = '[Products] ReactivateProduct';
  constructor(public productId: string) {}
}

export class LoadBundlesAction {
  static type = '[Products] LoadBundlesAction';
  constructor(public reload?: boolean) {}
}

export class LoadBundleAction {
  static type = '[Products] LoadBundleAction';
  constructor(public bundleId: string) {}
}

export class CreateBundleAction {
  static type = '[Products] CreateBundleAction';
  constructor(public bundle: Bundle) {}
}

export class UpdateBundleAction {
  static type = '[Products] UpdateBundleAction';
  constructor(public bundle: Bundle) {}
}

export class UpdateBundleChildProductsAction {
  static type = '[Products] UpdateBundleChildProductsAction';
  constructor(
    public bundleId: string,
    public oldChildProductsIds: string[],
    public childProductsIds: string[]
  ) {}
}

export class ActivateBundleAction {
  static type = '[Products] ActivateBundleAction';
  constructor(public bundleId: string) {}
}

export class DeactivateBundleAction {
  static type = '[Products] DeactivateBundleAction';
  constructor(public bundleId: string) {}
}

export class AddProductToBundleAction {
  static type = '[Products] AddProductToBundleAction';
  constructor(
    public bundleId: string,
    public productId: string
  ) {}
}

export class RemoveProductFromBundleAction {
  static type = '[Products] RemoveProductFromBundleAction';
  constructor(
    public bundleId: string,
    public productId: string
  ) {}
}
