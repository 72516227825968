import { AsyncPipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, DestroyRef, inject, Input, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import {
  DATEPICKER_DATE_FORMAT,
  DOCTORS_PARTNER_TYPES,
} from '@app/app.constants';
import { awbModels } from '@app/modules/configuration/configuration.types';
import { PartnersUtilsService } from '@app/modules/partners/partners-utils.service';
import { SamplePatientHistoryInformationComponent } from '@app/modules/service-data/components/sample/sample-dialog/sample-patient-history-information/sample-patient-history-information.component';
import { Sample } from '@app/modules/service-data/service-data.types';
import {
  handleValidators,
  ValidatorsHandlerType,
} from '@app/modules/service-data/service-data.utils';
import { GetProductInventoryItemListAction } from '@app/modules/service-data/store/product-intentory-item.actions';
import { ProductInventoryItemState } from '@app/modules/service-data/store/product-inventory-item.state';
import {
  CreateSampleAction,
  GetSampleAction,
  UpdateSampleAction,
} from '@app/modules/service-data/store/sample.actions';
import { SampleState } from '@app/modules/service-data/store/sample.state';
import { GetServiceDataAction } from '@app/modules/service-data/store/service-data.actions';
import { ServiceDataState } from '@app/modules/service-data/store/service-data.state';
import {
  AwbAddress,
  DataInputType,
  Kit,
  Partner,
  PartnerType,
  Product,
  ProductInventory,
} from '@core/core.types';
import { AuthenticationService } from '@core/services/authentication.service';
import { CountriesState } from '@core/store/countries/countries.state';
import { LoadPartnerFreeKitsAction } from '@core/store/kits/kits.actions';
import { KitsState } from '@core/store/kits/kits.state';
import { PartnerTypeState } from '@core/store/partner-types/partner-types.state';
import { LoadPartnersByIds } from '@core/store/partners/partners.actions';
import { PartnersState } from '@core/store/partners/partners.state';
import { ProductsListState } from '@core/store/products/products.state';
import { NbMomentDateService } from '@nebular/moment';
import {
  NbButtonModule,
  NbCardModule,
  NbCheckboxModule,
  NbDatepickerModule,
  NbDateService,
  NbDialogRef,
  NbIconModule,
  NbInputModule,
  NbOptionModule,
  NbRadioModule,
  NbSelectModule,
  NbTimepickerModule,
  NbToastrService,
} from '@nebular/theme';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { ValidationErrorComponent } from '@shared/components/validation-error/validation-error.component';
import { WaybillGenerationComponent } from '@shared/components/waybill-generation/waybill-generation.component';
import { InputValidationStatusDirective } from '@shared/directives/input-validation-status.directive';
import { SharedModule } from '@shared/shared.module';
import {
  PregnancyType,
  PriorScreeningTestType,
} from '@treatments/treatment.types';
import moment from 'moment';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import {
  catchError,
  filter,
  first,
  map,
  startWith,
  switchMap,
  tap,
} from 'rxjs/operators';

@Component({
  selector: 'app-sample-dialog',
  standalone: true,
  imports: [
    AsyncPipe,
    NbButtonModule,
    NbCardModule,
    NbIconModule,
    NbInputModule,
    TranslateModule,
    ReactiveFormsModule,
    NbOptionModule,
    SharedModule,
    NbSelectModule,
    NbDatepickerModule,
    WaybillGenerationComponent,
    NbCheckboxModule,
    ValidationErrorComponent,
    SamplePatientHistoryInformationComponent,
    InputValidationStatusDirective,
    NbRadioModule,
    NbTimepickerModule,
  ],
  providers: [{ provide: NbDateService, useClass: NbMomentDateService }],
  templateUrl: './sample-dialog.component.html',
  styleUrl: './sample-dialog.component.scss',
})
export class SampleDialogComponent implements OnInit {
  @Input() serviceDataId: string;
  @Input() sampleId: string;
  @Input() resampleKitId: string;

  address: AwbAddress = null;
  loading = false;
  hasMonoProduct = false;

  dovForm = this.fb.group({
    date: [moment(), Validators.required],
    time: [moment(), Validators.required],
  });

  protected form: FormGroup = this.fb.group({
    id: [null],
    partnerId: this.fb.nonNullable.control('', [Validators.required]),
    serviceDataId: [null, Validators.required],
    productId: [null, Validators.required],
    kitId: [null, Validators.required],
    dateOfVenipuncture: [null, Validators.required],
    shippingInformation: this.fb.group({
      waybill: ['', Validators.required],
      documentId: [null],
      address: [null],
    }),
    serviceInformation: this.fb.group({
      nipt: this.fb.group({
        doctorId: [{ value: null, disabled: true }],
        gestationAge: this.fb.group({
          day: [null],
          week: [null],
        }),
        heparinTherapy: this.fb.group({
          hasReceived: [null],
          isRelevant: [null],
        }),
        isGestationAgeAutomaticallyCalculated: [null],
        priorScreeningTest: this.fb.group({
          priorScreeningTestType: [null],
          firstTRisks: this.fb.group({
            t21Risk: [null],
            t18Risk: [null],
            t13Risk: [null],
          }),
          secondTRisks: this.fb.group({
            t21Risk: [null],
            t18Risk: [null],
            t13Risk: [null],
          }),
        }),
        ultrasoundDate: [null],
        patientHistory: this.fb.group({
          albuminTherapy: this.fb.group({
            hasReceived: [null],
            isRelevant: [null],
          }),
          cellularImmunotherapy: this.fb.group({
            hasReceived: [null],
            isRelevant: [null],
          }),
          stemCellTherapy: this.fb.group({
            hasReceived: [null],
          }),
          transplantSurgery: this.fb.group({
            hasReceived: [null],
          }),
          bloodTransfusion: this.fb.group({
            hasReceived: [null],
            isRelevant: [null],
          }),
        }),
      }),
    }),
    notes: [''],
  });

  protected doctors$: Observable<Partner[]>;
  protected kits$: Observable<Kit[]>;
  protected availableProducts$: Observable<Product[]>;

  protected awbModel$ = new BehaviorSubject(awbModels.pregenerated);

  protected DATEPICKER_DATE_FORMAT = DATEPICKER_DATE_FORMAT;

  private destroyRef = inject(DestroyRef);

  constructor(
    protected dialogRef: NbDialogRef<SampleDialogComponent>,
    private fb: FormBuilder,
    private store: Store,
    private partnersUtilsService: PartnersUtilsService,
    private toastrService: NbToastrService,
    private translateService: TranslateService,
    private authService: AuthenticationService
  ) {}

  ngOnInit() {
    this.store
      .select(ServiceDataState.getServiceDataById)
      .pipe(map((getById) => getById(this.serviceDataId)))
      .subscribe((serviceData) => {
        this.form.patchValue({
          partnerId: serviceData.partnerId,
          dateOfVenipuncture: moment(),
          serviceDataId: this.serviceDataId,
          serviceInformation: {
            nipt: {
              ...serviceData.serviceInformation.nipt
                .currentPregnancyInformation,
              isGestationAgeAutomaticallyCalculated:
                this.sampleId && !this.resampleKitId
                  ? false
                  : serviceData.serviceInformation.nipt
                      .currentPregnancyInformation
                      .isGestationAgeAutomaticallyCalculated,
              ultrasoundDate: moment(
                serviceData.serviceInformation.nipt.currentPregnancyInformation
                  .ultrasoundDate
              ),
              doctorId:
                serviceData.serviceInformation.nipt.doctorInformation.doctorId,
              patientHistory:
                serviceData.serviceInformation.nipt.patientHistory,
            },
          },
        } as any);

        if (this.sampleId) {
          this.prepareEditForm();
        }
      });

    this.form
      .get('partnerId')
      .valueChanges.pipe(
        startWith(this.form.get('partnerId').value),
        filter((partnerId) => !!partnerId),
        switchMap((partnerId: string) =>
          this.store.select(PartnersState.getPartnerById).pipe(
            map((getById) => getById(partnerId)),
            switchMap((partner: Partner) =>
              this.store
                .select(CountriesState.getCountryByName)
                .pipe(map((findById) => findById(partner?.address?.country)))
            )
          )
        )
      )
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((country) => {
        if (country) {
          this.awbModel$.next(country.awbModel);
        } else {
          this.awbModel$.next(awbModels.pregenerated);
        }
      });

    this.doctors$ = this.form.get('partnerId').valueChanges.pipe(
      startWith(this.form.get('partnerId').value),
      takeUntilDestroyed(this.destroyRef),
      filter((partnerId: string) => !!partnerId),
      switchMap((partnerId: string) =>
        this.partnersUtilsService.getPartnerById$(partnerId)
      ),
      filter((partner: Partner) => !!partner),
      switchMap((partner: Partner) =>
        this.store.dispatch(new LoadPartnersByIds(partner.subPartners)).pipe(
          switchMap(() =>
            this.store
              .select(PartnersState.getPartnersByIds)
              .pipe(map((findByIds) => findByIds(partner.subPartners)))
          ),
          switchMap((subPartners: Partner[]) =>
            this.store.select(PartnerTypeState.getPartnerTypesList).pipe(
              map((partnerTypesList: PartnerType[]) => {
                const medicalAdministrator = partnerTypesList.find(
                  (partnerType: PartnerType) =>
                    partnerType.type ===
                    DOCTORS_PARTNER_TYPES.medicalAdministrator
                );

                return subPartners.filter(
                  (doctor: Partner) =>
                    doctor?.partnerTypeId !== medicalAdministrator.id
                );
              })
            )
          ),
          switchMap((subPartners: Partner[]) =>
            this.authService
              .hasRequiredPermission$(
                'serviceData.details.sample.details.edit.editDoctorId'
              )
              .pipe(
                first(),
                map((hasPermission: boolean) => {
                  const doctorIdControl = this.form.get(
                    'serviceInformation.nipt.doctorId'
                  );
                  if (
                    (doctorIdControl.enabled && !subPartners.length) ||
                    (this.sampleId && !hasPermission)
                  ) {
                    doctorIdControl.disable();
                  } else if (!doctorIdControl.enabled && subPartners.length) {
                    doctorIdControl.enable();
                  }
                  return subPartners;
                })
              )
          )
        )
      )
    );

    this.kits$ = this.form.get('partnerId').valueChanges.pipe(
      startWith(this.form.get('partnerId').value),
      takeUntilDestroyed(this.destroyRef),
      filter((partnerId: string) => !!partnerId),
      switchMap((partnerId: string) =>
        this.store
          .dispatch(new LoadPartnerFreeKitsAction(partnerId))
          .pipe(map(() => partnerId))
      ),
      switchMap((partnerId: string) =>
        this.store
          .select(KitsState.getFreePartnerKits)
          .pipe(map((getFreePartnerKits) => getFreePartnerKits(partnerId)))
      )
    );

    this.availableProducts$ = this.store
      .select(ServiceDataState.getAvailableProducts)
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        map((getAvailableProducts) => getAvailableProducts(this.serviceDataId)),
        tap((products: Product[]) => {
          const productIdControl = this.form.get('productId');
          if ((productIdControl.enabled && !products.length) || this.sampleId) {
            productIdControl.disable();
          } else if (!productIdControl.enabled && products.length) {
            productIdControl.enable();
          }
        })
      );

    this.form
      .get('partnerId')
      .valueChanges.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.form.get('kitId').patchValue(null);
        this.form.get('serviceInformation.nipt.doctorId').patchValue(null);
      });

    const priorScreeningTypeControl = this.form.get(
      'serviceInformation.nipt.priorScreeningTest.priorScreeningTestType'
    );

    combineLatest([
      priorScreeningTypeControl.valueChanges.pipe(
        startWith(priorScreeningTypeControl.value)
      ),
      this.store
        .select(ServiceDataState.getServiceDataById)
        .pipe(
          map(
            (getById) =>
              getById(this.serviceDataId).serviceInformation.nipt
                .currentPregnancyInformation.pregnancyType
          )
        ),
    ])
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(([priorScreeningTestType, pregnancyType]) => {
        const priorScreeningGroup = this.form.get(
          'serviceInformation.nipt.priorScreeningTest'
        );

        if (priorScreeningTestType === PriorScreeningTestType.NoTestTaken) {
          priorScreeningGroup.get('firstTRisks').reset(null);
          priorScreeningGroup.get('firstTRisks').disable();
          priorScreeningGroup.get('secondTRisks').reset(null);
          priorScreeningGroup.get('secondTRisks').disable();
        } else {
          priorScreeningGroup.get('firstTRisks').enable();
          if (pregnancyType === PregnancyType.twins) {
            priorScreeningGroup.get('secondTRisks').enable();
          } else {
            priorScreeningGroup.get('secondTRisks').disable();
            priorScreeningGroup.get('secondTRisks').reset(null);
          }
        }
      });

    const gestationAgeAutoCalculateControl = this.form.get(
      'serviceInformation.nipt.isGestationAgeAutomaticallyCalculated'
    );

    combineLatest([
      gestationAgeAutoCalculateControl.valueChanges.pipe(
        startWith(gestationAgeAutoCalculateControl.value)
      ),
      this.store
        .select(ServiceDataState.getServiceDataById)
        .pipe(
          map(
            (getById) =>
              getById(this.serviceDataId).serviceInformation.nipt
                .currentPregnancyInformation.expectedConfinementDate
          )
        ),
      this.form
        .get('dateOfVenipuncture')
        .valueChanges.pipe(
          startWith(this.form.get('dateOfVenipuncture').value)
        ),
    ])
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(
        ([
          isGestationAgeAutoCalculated,
          expectedConfinementDate,
          dateOfVenipuncture,
        ]) =>
          this.calculateGestationAge(
            isGestationAgeAutoCalculated,
            expectedConfinementDate,
            dateOfVenipuncture
          )
      );

    this.form
      .get('productId')
      .valueChanges.pipe(
        switchMap((productId) =>
          this.store
            .select(ProductsListState.getProduct)
            .pipe(map((getById) => getById(productId)))
        )
      )
      .subscribe((product) => {
        this.hasMonoProduct = product?.dataInputType === DataInputType.MONO;
        handleValidators(
          [product],
          this.form,
          'serviceInformation.nipt',
          ValidatorsHandlerType.SAMPLE
        );
      });

    this.form
      .get('serviceInformation.nipt.heparinTherapy.hasReceived')
      .valueChanges.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((value: boolean) => {
        if (!value) {
          this.form
            .get('serviceInformation.nipt.heparinTherapy.isRelevant')
            .reset(null);
        }
      });

    this.dovForm.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        const dovValue = this.dovForm.getRawValue();
        this.form.patchValue({
          dateOfVenipuncture: moment(
            dovValue.date.format('DD/MM/YYYY') +
              ' ' +
              dovValue.time.format('HH:mm'),
            'DD/MM/YYYY HH:mm'
          ),
        });
      });
  }

  private prepareEditForm() {
    this.store
      .dispatch(new GetSampleAction(this.sampleId, false, true))
      .pipe(
        switchMap(() =>
          this.store
            .select(SampleState.getSampleById)
            .pipe(map((getById) => getById(this.sampleId)))
        ),
        first()
      )
      .subscribe((sample) => {
        this.address = sample.shippingInformation.address;
        if (this.resampleKitId) {
          sample = {
            ...sample,
            id: null,
            kitId: null,
            dateOfVenipuncture: new Date(),
            shippingInformation: {
              waybill: null,
            },
          };
        } else {
          this.form.get('kitId').disable();
          this.form.get('serviceInformation.nipt.patientHistory').disable();
          this.form.get('serviceInformation.nipt.heparinTherapy').disable();
        }

        this.form.patchValue({
          ...sample,
          dateOfVenipuncture: moment(sample.dateOfVenipuncture),
          serviceInformation: {
            ...sample.serviceInformation,
            nipt: {
              ...sample.serviceInformation.nipt,
              isGestationAgeAutomaticallyCalculated: this.resampleKitId
                ? sample.serviceInformation.nipt
                    .isGestationAgeAutomaticallyCalculated
                : false,
              ultrasoundDate: moment(
                sample.serviceInformation.nipt.ultrasoundDate
              ),
            },
          },
        });

        this.dovForm.patchValue({
          date: moment(sample.dateOfVenipuncture),
          time: moment(sample.dateOfVenipuncture),
        });
      });

    this.store
      .dispatch(new GetSampleAction(this.sampleId, false, false))
      .pipe(
        switchMap(() =>
          this.store.select(SampleState.getSampleById).pipe(
            map((getById) => getById(this.sampleId)),
            first(),
            switchMap((sample: Sample) =>
              this.store
                .dispatch(
                  new GetProductInventoryItemListAction(
                    {
                      ids: sample.productInventoryItems,
                      propertyKey: 'Exported',
                      propertyKeyExists: true,
                    },
                    null,
                    false
                  )
                )
                .pipe(
                  switchMap(() =>
                    this.store
                      .select(
                        ProductInventoryItemState.getProductInventoryItemsByIds
                      )
                      .pipe(
                        map((getPiisByIds) =>
                          getPiisByIds(sample.productInventoryItems)
                        ),
                        map((piis: ProductInventory[]) =>
                          piis.filter((pii) => pii)
                        ),
                        switchMap((piis: ProductInventory[]) =>
                          this.authService
                            .hasRequiredPermission$(
                              'serviceData.details.sample.details.edit.changeExportedDov'
                            )
                            .pipe(
                              first(),
                              tap((hasPermission) => {
                                if (piis.length && !hasPermission) {
                                  this.form.get('dateOfVenipuncture').disable();
                                } else {
                                  this.form.get('dateOfVenipuncture').enable();
                                }
                              })
                            )
                        )
                      )
                  )
                )
            )
          )
        )
      )
      .subscribe();
  }

  protected onWaybillGenerated(shippingInformation: {
    documentId: string;
    waybill: string;
    address: AwbAddress;
  }) {
    this.form.patchValue({ shippingInformation });
  }

  protected submit() {
    this.form.markAllAsTouched();
    const formValue = this.form.getRawValue();

    if (this.form.valid) {
      this.loading = true;
      const action = formValue.id ? UpdateSampleAction : CreateSampleAction;

      this.store
        .select(KitsState.getKitById)
        .pipe(
          switchMap((getKitById) => {
            const laboratory = getKitById(
              this.form.get('kitId').value
            ).laboratory;
            return this.store
              .dispatch(new action({ ...formValue, laboratory }))
              .pipe(
                switchMap(() =>
                  this.store.dispatch(
                    new GetServiceDataAction(this.serviceDataId, false, true)
                  )
                ),
                catchError((response: HttpErrorResponse) => {
                  this.toastrService.danger(
                    response.error?.Detail,
                    this.translateService.instant('toastr.error'),
                    {
                      destroyByClick: true,
                      duration: 0,
                      preventDuplicates: true,
                    }
                  );
                  return of(null);
                })
              );
          })
        )
        .subscribe(() => this.dialogRef.close());
    }
  }

  protected readonly awbModels = awbModels;
  protected readonly Object = Object;
  protected readonly PriorScreeningTestType = PriorScreeningTestType;

  private calculateGestationAge(
    isGestationAgeAutoCalculated: boolean,
    expectedConfinementDate,
    dateOfVenipuncture: moment.Moment
  ) {
    if (
      !isGestationAgeAutoCalculated ||
      !expectedConfinementDate ||
      !dateOfVenipuncture
    ) {
      return;
    }

    const startOfPregnancy = moment(expectedConfinementDate).subtract(280, 'd');

    const fullGestationDays = dateOfVenipuncture.diff(startOfPregnancy, 'd');
    const week = Math.floor(fullGestationDays / 7);
    const day = fullGestationDays % 7;

    this.form
      .get('serviceInformation.nipt.gestationAge')
      .patchValue({ day, week });
    this.form.get('serviceInformation.nipt.gestationAge').markAllAsTouched();
  }
}
