import {
  ProvisionSample,
  Sample,
} from '@app/modules/service-data/service-data.types';
import { SampleFilters } from '@app/modules/service-data/services/sample.service';
import { ApiHeaders } from '@core/core.types';

export const SAMPLES_NAME = 'Samples';
export class GetSamplesListAction {
  static type = `[${SAMPLES_NAME} GetSamplesListAction]`;

  constructor(
    public filters: SampleFilters,
    public headers: ApiHeaders,
    public pageToLoad: number,
    public reload: boolean
  ) {}
}
export class SetSamplesAction {
  static type = `[${SAMPLES_NAME} SetSamplesAction]`;

  constructor(
    public list: Sample[],
    public prependOnPage = false
  ) {}
}
export class GetSampleAction {
  static type = `[${SAMPLES_NAME} GetSampleAction]`;

  constructor(
    public id: string,
    public prependToPage = false,
    public reload?: boolean
  ) {}
}
export class CreateSampleAction {
  static type = `[${SAMPLES_NAME} CreateSampleAction]`;

  constructor(public sample: ProvisionSample) {}
}
export class UpdateSampleAction {
  static type = `[${SAMPLES_NAME} UpdateSampleAction]`;

  constructor(public sample: Sample) {}
}

export class RemoveSampleAction {
  static type = `[${SAMPLES_NAME} RemoveSampleAction]`;

  constructor(public sampleId: string) {}
}
