import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { KitsUtilsService } from '@app/modules/kits/kits-utils.service';
import { environment } from '@root/environments/environment';
import { Observable, ReplaySubject, Subject, throwError } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import {
  API_SPECIFICATION_CONSTANTS,
  KITS_API_CONSTANTS,
  MAX_URL_QUERY_PARAMETERS_IDS,
  NIFTY_APPLICATIONID_KEY,
  PAGING_HEADERS,
} from 'src/app/app.constants';
import { KITS_QUERY_CONSTANTS } from '../../@shared/constants/kit.constants';
import { ApiFilters, Kit, PaginationAndOrdering } from '../core.types';
import { CoreUtilsService } from '../utils/core-utils.service';
import { EntityBaseService } from './entityBase.service';

@Injectable({
  providedIn: 'root',
})
export class KitsService extends EntityBaseService<Kit> {
  private readonly BASE_KIT_API: string;
  private readonly BASE_GET_KITS_URL: string;
  private readonly API_URL = `${environment.apiUrl}/kits`;

  private itemsLoadingQueue: {
    id: string;
    subject: Subject<Kit>;
  }[] = [];
  private itemsLoadingSubject = new ReplaySubject<void>();

  constructor(
    protected http: HttpClient,
    private coreUtils: CoreUtilsService,
    private kitsUtilsService: KitsUtilsService
  ) {
    super(http);
    this.apiUrl = `${environment.apiUrl}/${KITS_API_CONSTANTS.kits}`;
    this.BASE_GET_KITS_URL =
      `${this.apiUrl}/${KITS_API_CONSTANTS.actorKitDirectory}/!/${API_SPECIFICATION_CONSTANTS.ask}` +
      `/${KITS_API_CONSTANTS.commandListKits}?${API_SPECIFICATION_CONSTANTS.applicationId}=${NIFTY_APPLICATIONID_KEY}`;
    this.BASE_KIT_API = `${this.apiUrl}/${KITS_API_CONSTANTS.actorKit}`;
    this.itemsLoadingSubject
      .pipe(debounceTime(300))
      .subscribe(() => this.loadQueuedItems());
  }

  getKit(kitId: string): Observable<Kit> {
    let loadingInProgress = this.itemsLoadingQueue.find((i) => i.id === kitId);
    if (!loadingInProgress) {
      loadingInProgress = {
        id: kitId,
        subject: new Subject<Kit>(),
      };
      this.itemsLoadingQueue.push(loadingInProgress);
      this.queueItemsLoad();
    }
    return loadingInProgress.subject.asObservable();
  }

  getKits(kitsIds: string[]): Observable<Kit[]> {
    this.logger.debug('get kits');
    const getKitsByIdsUrl =
      `${this.apiUrl}/${KITS_API_CONSTANTS.actorKitDirectory}/!/${API_SPECIFICATION_CONSTANTS.ask}/${KITS_API_CONSTANTS.commandListKits}?Ids=` +
      kitsIds.join(',');
    return this.http.get<Kit[]>(getKitsByIdsUrl);
  }

  getPartnerKits(paginationAndOrdering: PaginationAndOrdering) {
    this.logger.debug('get partner kits');
    return this.getFilteredEntities(paginationAndOrdering);
  }

  getAllPartnerKits(partnerId: string): Promise<Kit[]> {
    this.logger.debug('get partner kits');
    const partnerKitsUrl = `${this.BASE_GET_KITS_URL}&PartnerId=${partnerId}`;
    return this.coreUtils.getAllElements<Kit>(partnerKitsUrl);
  }

  getUnAssignedKits(paginationAndOrdering: PaginationAndOrdering) {
    this.logger.debug('get unassigned kits');
    const filters = paginationAndOrdering.filters || new Map<string, string>();
    filters.set(KITS_QUERY_CONSTANTS.Free, KITS_QUERY_CONSTANTS.True);
    return this.getFilteredEntities({
      ...paginationAndOrdering,
      filters,
    });
  }

  getAllKits(paginationAndOrdering: PaginationAndOrdering) {
    this.logger.debug('get all kits');
    return this.getFilteredEntities(paginationAndOrdering);
  }

  findKitsByBarcode(barcodeKey: string) {
    this.logger.debug('find kits by barcode');
    const kitsByBarcodeUrl = `${this.BASE_GET_KITS_URL}&BarcodeToken=${barcodeKey}`;
    return this.http.get<Kit[]>(kitsByBarcodeUrl);
  }

  getPartnerFreeKits(partnerId: string): Observable<Kit[]> {
    this.logger.debug('get partner free kits');
    const partnerFreeKitsUrl = `${this.BASE_GET_KITS_URL}`;

    const hideExpiresBefore = new Date();
    hideExpiresBefore.setMonth(hideExpiresBefore.getMonth() - 4);

    return this.http.get<Kit[]>(partnerFreeKitsUrl, {
      params: {
        PartnerId: partnerId,
        WithoutProductInventoryItems: 'True',
        expiresFrom: hideExpiresBefore.toISOString(),
      },
    });
  }

  getPartnersFreeKitsCount(partnerIds: string[]) {
    return this.http.get<{ partnerId: string; freeKits: number }[]>(
      `${this.apiUrl}/${KITS_API_CONSTANTS.actorKitDirectory}/!/ask/ListPartnersFreeKits`,
      {
        params: { partnerIds: partnerIds.join(',') },
      }
    );
  }

  async getPartnersFreeKits(partnerIds: string): Promise<Kit[]> {
    this.logger.debug('get partner free kits');
    const partnerFreeKitsUrl = `${this.BASE_GET_KITS_URL}&PartnerIds=${partnerIds}&WithoutProductInventoryItems=True`;
    let allLoaded = false;
    let allKits = [];
    let continuationToken = '';
    while (!allLoaded) {
      const response = await this.http
        .get(partnerFreeKitsUrl, {
          observe: 'response',
          headers: {
            [PAGING_HEADERS.continuationToken]: continuationToken,
          },
        })
        .toPromise();
      allKits = allKits.concat(response.body);
      if (response.headers.has(PAGING_HEADERS.continuationToken)) {
        continuationToken = response.headers.get(
          PAGING_HEADERS.continuationToken
        );
      } else {
        allLoaded = true;
      }
    }
    return allKits;
  }

  markKitAsShipped(kitId: string, date: Date) {
    this.logger.debug('mark kit as shipped');
    const markKitAsShippedUrl = `${this.BASE_KIT_API}/${kitId}/${API_SPECIFICATION_CONSTANTS.tell}/${KITS_API_CONSTANTS.commandMarkAsShipped}`;
    return this.http.post(markKitAsShippedUrl, null);
  }

  markKitAsReturned(kitId: string, date: Date): Observable<any> {
    this.logger.debug('mark kit as returned');
    const markKitAsReturnedUrl = `${this.BASE_KIT_API}/${kitId}/${API_SPECIFICATION_CONSTANTS.tell}/${KITS_API_CONSTANTS.commandMarkAsReturned}`;

    const body = {
      Returned: date,
    };
    return this.http.post(markKitAsReturnedUrl, body);
  }

  assignPartnerToKit(kitId: string, partnerId: string): Observable<any> {
    this.logger.debug('assign partner to kit');
    const assignPartnerToKitUrl = `${this.BASE_KIT_API}/${kitId}/${API_SPECIFICATION_CONSTANTS.tell}/${KITS_API_CONSTANTS.commandAssignPartnerToKit}`;
    const body = { partnerId };
    return this.http.post(assignPartnerToKitUrl, body);
  }

  assignProductInventoryItem(kitId: string, productInventoryItemId: string) {
    const assignPii = `${this.BASE_KIT_API}/${kitId}/${API_SPECIFICATION_CONSTANTS.tell}/${KITS_API_CONSTANTS.commandAssignProductInventoryItem}`;

    return this.http.post(assignPii, { productInventoryItemId });
  }

  unassignProductInventoryItem(kitId: string, productInventoryItemId: string) {
    const unassignPii = `${this.BASE_KIT_API}/${kitId}/${API_SPECIFICATION_CONSTANTS.tell}/${KITS_API_CONSTANTS.commandUnassignProductInventoryItem}`;

    return this.http.post(unassignPii, { productInventoryItemId });
  }

  protected setUrlBody(kit: Kit) {
    this.logger.debug('set kit url body');
    const body = {
      expiryDate: kit.expiryDate,
      notes: kit.notes && kit.notes.length > 0 ? kit.notes : '',
      laboratory: kit.laboratory,
    };
    return body;
  }

  protected setCreateEntityBody(entity: Kit, body: Partial<Kit>) {
    const partialBody = {
      partnerId: entity.partnerId,
      applicationId: NIFTY_APPLICATIONID_KEY,
      properties: entity.properties,
    };

    body = Object.assign(body, partialBody);
    return body;
  }

  protected setUpdateEntityBody(entity: Kit, body: Partial<Kit>) {
    body.properties = entity.properties;
    return body;
  }

  protected getListUrl(): string {
    this.logger.debug('get list url');
    return this.BASE_GET_KITS_URL;
  }

  protected getUpdateEntityUrl(entityId: string): string {
    return `${this.BASE_KIT_API}/${entityId}/${API_SPECIFICATION_CONSTANTS.tell}/${KITS_API_CONSTANTS.commandUpdateKit}`;
  }

  protected getDeleteEntityUrl(entityId: string): string {
    return `${this.BASE_KIT_API}/${entityId}/${API_SPECIFICATION_CONSTANTS.tell}/${KITS_API_CONSTANTS.commandDeleteKit}`;
  }

  protected getCreateEntityUrl(newEntityId: string): string {
    return `${this.BASE_KIT_API}/${newEntityId}/${API_SPECIFICATION_CONSTANTS.tell}/${KITS_API_CONSTANTS.commandGenerateKit}`;
  }

  protected getFetchEntityUrl(entityId: string): string {
    throw new Error('Method not implemented.');
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      this.logger.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      this.logger.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    // return an observable with a user-facing error message
    return throwError('Something bad happened; please try again later.');
  }

  private queueItemsLoad() {
    this.logger.debug('queue item load');
    if (this.itemsLoadingQueue.length > MAX_URL_QUERY_PARAMETERS_IDS) {
      this.loadQueuedItems();
    } else {
      this.itemsLoadingSubject.next();
    }
  }

  private async loadQueuedItems() {
    if (this.itemsLoadingQueue.length) {
      const elementsSize = Math.min(
        this.itemsLoadingQueue.length,
        MAX_URL_QUERY_PARAMETERS_IDS
      );
      const itemsToLoad = this.itemsLoadingQueue.splice(0, elementsSize);
      const itemsIdsToLoad = itemsToLoad
        .map((itl) => itl.id)
        .filter((e, i, a) => e && a.indexOf(e) === i);
      let loadedItems: Kit[] = [];
      try {
        loadedItems = await this.getKits(itemsIdsToLoad).toPromise();
        // eslint-disable-next-line no-empty
      } catch (e) {}
      for (const itl of itemsToLoad) {
        const item = loadedItems.find((li) => li.id === itl.id);
        itl.subject.next(item ? item : null);
        itl.subject.complete();
      }
      this.queueItemsLoad();
    }
  }

  getKitsList(filters: KitsFilters) {
    return this.http.get<Kit[]>(
      `${this.apiUrl}/${KITS_API_CONSTANTS.actorKitDirectory}/!/${API_SPECIFICATION_CONSTANTS.ask}/${KITS_API_CONSTANTS.commandListKits}`,
      {
        params: this.kitsUtilsService.buildHttpParams(filters),
      }
    );
  }
}

export interface KitsFilters extends ApiFilters {
  ids?: string[];
  laboratory?: string;
  productInventoryItemIds?: string[];
}
