import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ChangeDetectorRef, NgZone } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import {
  MOMENT_DATE_FORMAT_DASH_REVERSED,
  MOMENT_DATE_TIME_FORMAT,
  MOMENT_DATE_TIME_SECONDS_FORMAT,
  NAME_ANONYMIZE,
  NOTIFICATION_EMAILS_CONSTANTS,
  PAGING_HEADERS,
  ServiceType,
} from '@app/app.constants';
import {
  DocumentDescription,
  DocumentType,
  ProductInventoryItemStatus,
  Sample,
} from '@app/modules/service-data/service-data.types';
import {
  DsvCategory,
  Treatment,
  TreatmentDocumentKeywords,
} from '@treatments/treatment.types';
import * as FileSaver from 'file-saver';
import JSZip from 'jszip';
import moment, { Moment } from 'moment';
import { Observable, concat, of } from 'rxjs';
import { catchError, map, toArray } from 'rxjs/operators';
import * as XLSX from 'xlsx';
import {
  AnalysisType,
  AppInfo,
  PaginationAndOrdering,
  Product,
  ProductInventory,
  Properties,
  RecipientModel,
  Run,
} from '../core.types';

export class Util {
  static RemoveEmptyProperties(obj: any) {
    Object.keys(obj).forEach((key) =>
      obj[key] === null || obj[key] === undefined ? delete obj[key] : {}
    );
    return obj;
  }

  static HasProperties(obj: any, properties: string[]) {
    return properties.every((key) =>
      Object.prototype.hasOwnProperty.call(obj, key)
    );
  }

  static PropertiesToObject(properties: Properties[] = []): any {
    const obj = {};
    for (const property of properties) {
      obj[property.key?.toLowerCase()] = property.value;
    }
    return obj;
  }

  static CreateGuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      // eslint-disable-next-line no-bitwise
      const r = (Math.random() * 16) | 0;
      // eslint-disable-next-line no-bitwise
      const v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }

  /*
   * Marks all controls in a form group as touched
   * @param formGroup - The group to clear
   */
  static MarkFormGroupTouched(formGroup: any) {
    Object.keys(formGroup.controls).map((control: any) => {
      formGroup.controls[control].markAsTouched();

      if (formGroup.controls[control].controls) {
        Util.MarkFormGroupTouched(formGroup.controls[control]);
      }
    });
  }

  static GetEmailPattern() {
    return '.+@.+\\..+';
  }

  static GetTreatmentRiskPattern() {
    return '^\\d+|^$';
  }

  static UpdateComponentView(
    cdr: ChangeDetectorRef,
    ngZone: NgZone,
    updateModelFunction: () => void
  ): Promise<void> {
    return ngZone.run(() => {
      updateModelFunction();
      cdr.markForCheck();
      return Promise.resolve();
    });
  }

  static GetHttpHeadersFromPaginationAndOrderingWithCT(
    listOptions?: PaginationAndOrdering
  ) {
    let headers = new HttpHeaders();
    if (listOptions) {
      if (listOptions.orderDirection) {
        headers = headers.append(
          PAGING_HEADERS.orderDirection,
          listOptions.orderDirection
        );
      }
      if (listOptions.orderBy) {
        headers = headers.append(PAGING_HEADERS.orderBy, listOptions.orderBy);
      }
      if (listOptions.top) {
        headers = headers.append(
          PAGING_HEADERS.top,
          listOptions.top.toString()
        );
      }
      if (listOptions.continuationToken) {
        headers = headers.append(
          PAGING_HEADERS.continuationToken,
          listOptions.continuationToken
        );
      }
    }
    return headers;
  }

  static ParseHttpHeadersResponseAndExtendResponse() {
    return map((response: any) => {
      const { headers } = response;

      if (headers) {
        let continuationToken = null;
        let totalCount = null;

        if (headers.has(PAGING_HEADERS.continuationToken)) {
          continuationToken = headers.get(PAGING_HEADERS.continuationToken);
        }

        if (headers.has(PAGING_HEADERS.totalCount)) {
          totalCount = parseInt(headers.get(PAGING_HEADERS.totalCount), 10);
        }

        return {
          ...{ data: response.body },
          continuationToken,
          totalCount,
        };
      }

      return { ...{ data: response.body } };
    });
  }

  static ConvertBase64ToBlob(base64: string): BlobPart {
    // decode base64 string, remove space for IE compatibility
    const binary = atob(base64.replace(/\s/g, ''));
    const len = binary.length;
    const buffer = new ArrayBuffer(len);
    const view = new Uint8Array(buffer);
    for (let i = 0; i < len; i++) {
      view[i] = binary.charCodeAt(i);
    }

    return view;
  }

  static GetBase64BytesLength(base64: string): number {
    const binary = atob(base64.replace(/\s/g, ''));
    return binary.length;
  }

  static SaveDocxFile(base64: string, fileName: string) {
    const docxBlob = new Blob([Util.ConvertBase64ToBlob(base64)], {
      type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    });
    FileSaver.saveAs(docxBlob, `${fileName}.docx`);
  }

  static SavePdfFile(base64: string, fileName: string) {
    const docxBlob = new Blob([Util.ConvertBase64ToBlob(base64)], {
      type: 'application/pdf',
    });
    FileSaver.saveAs(docxBlob, `${fileName}.pdf`);
  }

  static GetExtensionFromFileName(fileName: string): string {
    return fileName.substr(fileName.lastIndexOf('.') + 1);
  }

  static GetUrlFromBase64String(base64: string): string {
    const arrBuffer = Util.ConvertBase64ToBlob(base64);

    // It is necessary to create a new blob object with mime-type explicitly set
    // otherwise only Chrome works like it should
    const newBlob = new Blob([arrBuffer], { type: 'application/pdf' });

    // IE doesn't allow using a blob object directly as link href
    // instead it is necessary to use msSaveOrOpenBlob
    // removed with Angular 13 upgrade
    // if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    //   window.navigator.msSaveOrOpenBlob(newBlob);
    //   return;
    // }

    // For other browsers:
    // Create a link pointing to the ObjectURL containing the blob.
    return window.URL.createObjectURL(newBlob);

    // For directly downloading
    /*const link = document.createElement('a');
    document.body.appendChild(link); // required in FF, optional for Chrome
    link.href = data;
    link.download = 'file.pdf';
    link.click();
    window.URL.revokeObjectURL(data);
    link.remove();*/
  }

  static isEmpty = (value: any): boolean =>
    value == null ||
    (typeof value === 'object' && Object.keys(value).length === 0) ||
    (typeof value === 'string' && value.trim().length === 0);

  static getBase64ImageFromUrl(blob: Blob): Observable<string> {
    return new Observable((subscriber) => {
      const reader = new FileReader();
      reader.addEventListener(
        'load',
        () => {
          subscriber.next((reader.result as string).split(',')[1]);
          subscriber.complete();
        },
        false
      );

      reader.onerror = () => {
        subscriber.error(this);
        subscriber.complete();
      };
      reader.readAsDataURL(blob);
    });
  }

  static checkIfBlobExist(httpCLient: HttpClient, uri: string) {
    return httpCLient
      .get(uri, { responseType: 'blob' })
      .pipe(catchError(() => of(null)))
      .toPromise();
  }

  static getBase64ImageFromFile(file: File): Observable<any> {
    return new Observable((subscriber) => {
      const reader = new FileReader();
      reader.addEventListener(
        'load',
        () => {
          subscriber.next((reader.result as string).split(',')[1]);
          subscriber.complete();
        },
        false
      );
      reader.onerror = () => {
        subscriber.error(this);
        subscriber.complete();
      };
      reader.readAsDataURL(file);
    });
  }

  static formatFilterDateString(dateString: string | Moment, dateOnly = false) {
    if (dateString) {
      const format = dateOnly
        ? MOMENT_DATE_FORMAT_DASH_REVERSED
        : MOMENT_DATE_TIME_SECONDS_FORMAT;
      return moment(dateString, MOMENT_DATE_TIME_FORMAT).format(format);
    }
    return '';
  }

  static formatFilterDateMoment(m: moment.Moment, dateOnly = false): string {
    if (m) {
      const format = dateOnly
        ? MOMENT_DATE_FORMAT_DASH_REVERSED
        : MOMENT_DATE_TIME_SECONDS_FORMAT;
      return m.format(format);
    }
    return '';
  }

  static anyItemLoaded(items: any[]): boolean {
    return items && (!items.length || items.some((item) => !!item));
  }

  static createJsZip(
    folderName: string,
    files: {
      fileName: string;
      content: string;
      suffix?: string;
      options?: any;
    }[],
    fileSuffix = 'html'
  ): Promise<Blob> {
    const jsZip = new JSZip();
    files.forEach((f) => {
      const fileName = folderName ? `${folderName}\\${f.fileName}` : f.fileName;
      const suffix = f.suffix ? f.suffix : fileSuffix;
      jsZip.file(
        `${fileName}.${suffix}`,
        f.content,
        f.options ? f.options : {}
      );
    });
    return jsZip.generateAsync({
      type: 'blob',
      compression: 'DEFLATE',
    });
  }

  static async downloadHtmlRequestForms(
    folderName: string,
    files: { fileName: string; content: string }[]
  ) {
    const zipArchive = await Util.createJsZip(folderName, files);
    FileSaver.saveAs(zipArchive, `request_forms_${folderName}.zip`);
  }

  static async downloadPdfRequestForms(
    folderName: string,
    files: { fileName: string; content: string }[]
  ) {
    const zipArchive = await Util.createJsZip(
      folderName,
      files.map((f) => ({
        ...f,
        options: {
          base64: true,
        },
      })),
      'pdf'
    );
    FileSaver.saveAs(zipArchive, `request_forms_${folderName}.zip`);
  }

  static loadLogo(): string {
    if (window.location.href.indexOf('stg') > -1) {
      return 'assets/images/geneplanet-logo-stg.svg';
    } else {
      return '/assets/images/geneplanet-logo.svg';
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
  static chunkArray<T extends any>(myArray: T[], chunkSize: number): T[][] {
    const arrayLength = myArray.length;
    const tempArray = [];

    for (let index = 0; index < arrayLength; index += chunkSize) {
      const myChunk = myArray.slice(index, index + chunkSize);
      // Do something if you want with the group
      tempArray.push(myChunk);
    }

    return tempArray;
  }

  static removeDuplicates(myArray: string[]): string[] {
    return [...new Set(myArray)];
  }

  static getReportTypeFromName(fn: string): string {
    const fileName = fn.toLowerCase();
    if (
      fileName.includes(TreatmentDocumentKeywords.highRisk1) ||
      fileName.includes(TreatmentDocumentKeywords.highRisk2)
    ) {
      return DocumentType.HighRisk;
    } else if (fileName.includes(TreatmentDocumentKeywords.delay)) {
      return DocumentType.Delay;
    } else if (
      fileName.includes(TreatmentDocumentKeywords.resample1) ||
      fileName.includes(TreatmentDocumentKeywords.resample2)
    ) {
      return DocumentType.Resample;
    } else if (fileName.includes(TreatmentDocumentKeywords.refund)) {
      return DocumentType.Refund;
    } else if (fileName.includes(TreatmentDocumentKeywords.notqualified)) {
      return DocumentType.NotQualified;
    } else if (fileName.includes(TreatmentDocumentKeywords.canceled)) {
      return DocumentType.Canceled;
    } else if (fileName.includes(TreatmentDocumentKeywords.nocall)) {
      return DocumentType.NoCall;
    } else if (fileName.includes(TreatmentDocumentKeywords.official)) {
      return DocumentType.OfficialReport;
    }
  }

  static getDocumentDescriptionFromName(fn: string): string {
    const fileName = fn.toLowerCase();
    if (fileName.includes(TreatmentDocumentKeywords.notqualifiedbylaboratory)) {
      return DocumentDescription.ByLaboratory;
    } else if (
      fileName.includes(TreatmentDocumentKeywords.notqualifiedbypartner)
    ) {
      return DocumentDescription.ByPartner;
    }

    return '';
  }

  // split string by document type and then split it by -. Document reason is either right next to type or offset by one depending on document type
  static getDocumentReason(fn: string): string {
    const fnLowerCase = fn.toLowerCase();

    if (fn.includes(DocumentType.NotQualified)) {
      const halfSplit = fn.split(DocumentType.NotQualified + '-');
      return halfSplit[1].split('-')[1].split('_')[0];
    }

    if (fn.includes(DocumentType.Canceled)) {
      const halfSplit = fn.split(DocumentType.Canceled + '-');
      return halfSplit[1].split('-')[0].split('_')[0];
    }

    if (
      fnLowerCase.includes(TreatmentDocumentKeywords.highRisk1) ||
      fnLowerCase.includes(TreatmentDocumentKeywords.highRisk2)
    ) {
      const unifiedString = fnLowerCase.replace(
        TreatmentDocumentKeywords.highRisk2,
        TreatmentDocumentKeywords.highRisk1
      );

      const halfSplit = unifiedString.split(
        TreatmentDocumentKeywords.highRisk1
      );
      const reasonsSplit = halfSplit[1].split('-');
      reasonsSplit.shift();
      return reasonsSplit
        .map((reason: string) => reason.split('_')[0].toUpperCase())
        .join(', ');
    }

    if (fnLowerCase.includes(TreatmentDocumentKeywords.refund)) {
      return DocumentType.NoCall;
    }

    return null;
  }

  static getAnalysisTypeFromName(fn: string): string {
    const fileName = fn.toLowerCase();
    if (fileName.includes('analysistype-' + AnalysisType.NIPT.toLowerCase())) {
      return AnalysisType.NIPT;
    } else if (
      fileName.includes('analysistype-' + AnalysisType.RH.toLowerCase())
    ) {
      return AnalysisType.RH;
    } else if (
      fileName.includes('analysistype-' + AnalysisType.MONO.toLowerCase())
    ) {
      return AnalysisType.MONO;
    }
  }

  static addAnalysisTypeToName(filename: string, analysisType): string {
    if (filename.toLowerCase().includes('-analysistype-')) {
      return filename;
    }

    const gpString = 'geneplanet';
    const gpEndLocation =
      filename.toLowerCase().indexOf(gpString) + gpString.length;
    if (!filename.toLowerCase().indexOf(gpString)) {
      return filename;
    }
    return [
      filename.slice(0, gpEndLocation),
      `-AnalysisType-${analysisType}`,
      filename.slice(gpEndLocation),
    ].join('');
  }
}

export const validatePassword = (c: UntypedFormControl) => {
  const PASSWORD_REGEXP = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/;
  return !c.value || PASSWORD_REGEXP.test(c.value)
    ? null
    : {
        passwordStrength: {
          valid: false,
        },
      };
};

export const getKitRuns = (kitId: string, runs: Run[]): Run[] => {
  if (kitId && runs) {
    return runs.filter(
      (r) => r.kits && r.kits.findIndex((k) => k.kitId === kitId) > -1
    );
  }
  return null;
};

export const getRunsKitsIdsUnion = (runs: Run[]): string[] =>
  runs
    .reduce(
      (arr, run) =>
        run.kits ? [...arr, ...run.kits.map((k) => k.kitId)] : arr,
      []
    )
    .filter(
      (kitId: string, index: number, arr: string[]) =>
        arr.indexOf(kitId) === index
    );

export const getRunsKitsIdsCrossSection = (runs: Run[]): string[] => {
  let kitIds = [];
  if (runs && runs.length) {
    for (let i = 0; i < runs.length; i++) {
      const run = runs[i];
      if (i === 0) {
        kitIds = run.kits.map((rk) => rk.kitId);
      } else {
        kitIds = kitIds.filter(
          (kitId) => run.kits.findIndex((rk) => rk.kitId === kitId) > -1
        );
      }
    }
  }
  return kitIds;
};

export const sortRunByDateGenerated = (runs: Run[]) => {
  if (!runs || !runs.length) {
    return runs;
  }
  return runs.sort((a, b) =>
    moment(a.generated.timestamp).isBefore(moment(b.generated.timestamp))
      ? 1
      : -1
  );
};

export const getPartnerServiceName = (products: Product[]) =>
  products?.[0]?.brand || ServiceType.NIPT;

export const getRecipientsString = (recipients: RecipientModel[]) => {
  if (recipients && recipients.length) {
    return recipients.map((r) => r.recipient).join(', ');
  }
  return '';
};

export const replaceNotificationEmailTemplateTerms = (
  term: string,
  serviceName: string,
  kitId: string,
  highRiskReason: string
): string => {
  term = term
    .split(NOTIFICATION_EMAILS_CONSTANTS.serviceNameReplaceString)
    .join(serviceName);
  term = term
    .split(NOTIFICATION_EMAILS_CONSTANTS.barcodeReplaceString)
    .join(kitId);
  term = term
    .split(NOTIFICATION_EMAILS_CONSTANTS.highRiskReasonReplaceString)
    .join(highRiskReason);
  return term;
};

export const isBMIOver40 = (weight: number, height: number): boolean => {
  const bmi = calculateBMI(weight, height);
  if (bmi > 40) {
    return true;
  }
  return false;
};

export const calculateBMI = (weight: number, height: number): number => {
  const heightInMeters = height / 100;
  return weight / (heightInMeters * heightInMeters);
};

export const mergeCountryFilter = (
  roleCountries: string[],
  countries: string[]
): string => {
  const selectedCountries: string[] = [];
  if (countries && countries.length) {
    for (let country of countries) {
      country = country.toLowerCase();
      if (!roleCountries || !roleCountries.length) {
        return countries.join(',');
      } else if (roleCountries.length && roleCountries.indexOf(country) > -1) {
        selectedCountries.push(country);
      }
      return selectedCountries.join(',');
    }
  } else if (roleCountries && roleCountries.length) {
    return roleCountries.join(',');
  }
  return null;
};

export const roundToTwoDecimals = (value: number): number =>
  Math.round((value + Number.EPSILON) * 100) / 100;

export const parseNumber = (value: any): number => {
  if (value) {
    const numberValue = value * 1;
    if (!isNaN(numberValue)) {
      return numberValue;
    }
  }
  return 0;
};

export const readXslxFromBase64 = async (
  blobBase64: string,
  password?: string
) => {
  const wb: XLSX.WorkBook = XLSX.read(blobBase64, { type: 'base64', password });
  const wsName: string = wb.SheetNames[0];
  const ws: XLSX.WorkSheet = wb.Sheets[wsName];
  const sheetData = XLSX.utils.sheet_to_json(ws, {
    header: 1,
    defval: null,
  });
  return sheetData as any[][];
};

export const performSequentialCommands = (commands: Observable<any>[]) => {
  if (!commands || commands.length === 0) {
    return of([]);
  }
  return concat(...commands).pipe(toArray());
};

export const getSafeUTCDate = (date: Moment): Moment => {
  if (date && moment.isMoment(date)) {
    return moment.utc(date.format(MOMENT_DATE_FORMAT_DASH_REVERSED) + 'T12:00');
  }
  return date;
};

export const removeFiltersByKeys = (filters: any, keys: string[]): any => {
  const objecKeys = Object.keys(filters);
  if (filters && objecKeys.length > 0 && keys && keys.length > 0) {
    const filterKeysToRemove = objecKeys.filter((f) =>
      keys.find((k) => k === f)
    );
    for (const removeKey of filterKeysToRemove) {
      delete filters[removeKey];
    }
  }
  return filters;
};

export const getFileNameWithoutExtension = (fileName: string): string => {
  return fileName.replace('.docx', '').replace('.doc', '').replace('.pdf', '');
};

export const getAppInfoDisplayString = (appInfo: AppInfo): string => {
  if (appInfo) {
    return `${appInfo.tag} (${appInfo.hash})`;
  }
  return '';
};

export const isNumber = (value: any): boolean => !isNaN(toInteger(value));

export const toInteger = (value: any): number => parseInt(value, 10);

export const checkWindowLocationUrl = (): boolean => {
  if (
    window.location.href.indexOf('localhost') > -1 ||
    window.location.href.indexOf('-stg') > -1
  ) {
    return true;
  } else {
    return false;
  }
};

export const calculateSimpleCode = (kitId: string): string => {
  const simpleCode = kitId.replace(/[^0-9]/g, '');
  if (
    (simpleCode.length !== 9 && simpleCode.length !== 10) ||
    isNaN(+simpleCode) ||
    +simpleCode <= 206301322
  ) {
    return null;
  }
  const simpleCodeNumber = +simpleCode - 99998663;
  const hexSimpleCode = simpleCodeNumber.toString(16);
  return hexSimpleCode.toUpperCase();
};

export const getPatientNameDisplayString = (treatment: Treatment): string => {
  if (treatment && treatment.patientInformation) {
    const { name, firstName, lastName } = treatment.patientInformation;
    return [firstName, lastName].filter((p) => !!p).join(' ') || name || '';
  }
  return '';
};

export const anonymizeName = (name: string): string => {
  const names = name.toLowerCase().split(' ');
  const firstName = names.shift();
  const lastName = names.join(' ');

  return (
    (NAME_ANONYMIZE.firstNames[firstName[0]] ??
      NAME_ANONYMIZE.firstNames.default) +
    ' ' +
    (NAME_ANONYMIZE.lastNames[lastName[0]] ?? NAME_ANONYMIZE.lastNames.default)
  );
};

export const generatePassword = (
  length = 20,
  wishlist = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz~!@-#$'
) =>
  Array.from(crypto.getRandomValues(new Uint32Array(length)))
    .map((x) => wishlist[x % wishlist.length])
    .join('');

export const getDaysSinceVenipuncture = (
  pii: ProductInventory,
  sample: Sample
) => {
  if (!sample?.dateOfVenipuncture) {
    return 0;
  }
  const endDate = pii.processingFinished
    ? moment(pii.processingFinished.timestamp)
    : moment();
  const diff = endDate.diff(moment(sample.dateOfVenipuncture));
  return Math.floor(diff / (24 * 3600000));
};

export const getPiiDovCategory = (
  pii: ProductInventory,
  dsv: number
): DsvCategory => {
  const delay = !!pii.properties.find((prop) => prop.key === 'Delay');

  if (delay && dsv >= 14) {
    return DsvCategory.red;
  }
  if (delay) {
    return DsvCategory.blue;
  }
  if (pii.lastStatus === ProductInventoryItemStatus.processingFailed) {
    return DsvCategory.gray;
  }
  if (pii.lastStatus === ProductInventoryItemStatus.canceled) {
    return DsvCategory.none;
  }

  if (pii.documents.length === 0) {
    if (dsv >= 10) {
      return DsvCategory.red;
    }
    if (dsv >= 7) {
      return DsvCategory.orange;
    }
  }
  if (
    dsv >= 0 &&
    dsv <= 6 &&
    pii.lastStatus === ProductInventoryItemStatus.processingStarted
  ) {
    return DsvCategory.green;
  }
  return DsvCategory.none;
};
