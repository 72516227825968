import { Laboratory } from '@shared/constants/kit.constants';
import { Timestamp } from '@treatments/treatment.types';

export interface EntityBase {
  id: string;
}

export interface Credentials {
  accessToken: string;
  refreshToken: string;
  userId: string;
}
export interface Language {
  id: string;
  name: string;
  isoCode: string;
  generated?: string;
  generatedBy?: string;
  updated?: string;
  updatedBy?: string;
  deactivated?: string;
  deactivatedBy?: string;
}
export enum UserRoles {
  admin = 'NIPT-biotech-process-manager',
  itDevelopment = 'NIPT-IT-development',
  local = 'NIPT-local',
  doctorsSupport = 'NIPT-doctors-support',
  accountantManager = 'NIPT-accountant-manager',
  customerSupport = 'NIPT-customer-support',
  labOperations = 'NIPT-lab-operations',
  labWorker = 'NIPT-lab-worker',
  invoiceExporter = 'NIPT-invoice-exporter',
  highRiskViewer = 'NIPT-high-risk-viewer',
  marketing = 'NIPT-marketing',
}
export interface ErrorMessage {
  original: Original;
  statusCode: number;
  code: string;
  description: string;
}
export interface Original {
  original?: any;
  response: Response;
  status: number;
}
export interface LoginUserData {
  username: string;
  password: string;
}
export interface UserProfileData {
  id: string;
  email: string;
  lastLogin: Date;
  lastIp: string;
  emailVerified: boolean;
  blocked: boolean;
  partnerId: string;
}

export interface Generated {
  by: string;
  timestamp: Date;
}

export interface Deactivated {
  by: string;
  timestamp: Date;
}

export interface Bundle {
  id: string;
  name: string;
  productIds: string[];
  wholesalePriceExternalId?: string;
  endUserPriceExternalId?: string;
  doctorFeeExternalId?: string;
  deactivated?: Status;
  generated?: Date;
}
export interface Product {
  id: string;
  productId?: string;
  name: string;
  brand: string;
  internalName: string;
  productSubTypes: string[];
  applicationId: string;
  generated?: Status;
  deactivated?: Status;
  childProductIds?: string[];
  properties?: ProductConfigurationProperties[];
  chapters?: any[];
  analyses?: any[];
  externalId?: string;
  externalId2?: string;
  externalId3?: string;
  type?: string[];
  incidentalFindings?: boolean;
  analysisProductCode: { [key in Laboratory]?: string };
  analysisType: AnalysisType;
  dataInputType: DataInputType;
}

export interface SortSettings {
  sortDirection: SortDirection;
  orderBy: string;
}

export enum SortDirection {
  asc = 'asc',
  desc = 'desc',
}

export interface Status {
  by: string;
  timestamp: Date;
}

export interface Kit extends EntityBase {
  numericBarcode?: number;
  expiryDate?: Date;
  partnerId?: string;
  notes?: string;
  laboratory?: string;
  generated?: Status;
  shipped?: Status;
  returned?: Status;
  sentToLaboratory?: Status;
  failed?: Status;
  canceled?: Status;
  deprovisioned?: Status;
  locked?: Status;
  lastStatus: string;
  lastStatusChanged?: Date;
  lastStatusChangedBy?: string;
  productInventoryItemIds?: string[];
  applicationIds: string[];
  properties?: Properties[];
  type: string;
  expiresSoon?: boolean;
}

export interface Page<T> {
  pageNumber?: number;
  items: T[];
  continuationToken: string;
  totalCount: number;
}

export interface Partner extends EntityBase {
  name: string;
  email: string;
  phoneNumber: string;
  deactivated?: Status;
  partnerTypeId: string;
  applicationId: string;
  applications?: string[];
  address: Address;
  languages: string[];
  languageName?: string;
  properties: Properties[];
  geneticCounselor?: boolean;
  barcodeRanges: any[];
  productConfigurations?: string[];
  bundleConfigurations?: string[];
  subPartners: string[];
  notes: string;
  accountManagerId: string;
  userAccounts: PartnerUserAccount[];
  minimalSupply?: number;
  externalId: string;
  notificationProfiles: string[];
  paymentTypes: string[];
  registered?: any;
  onlineReport: boolean;

  // Doctor properties
  username?: string;
  password?: string;
  application?: string;
}
export interface Address {
  street: string;
  zip: string;
  city: string;
  country: string;
  countryIsoCode?: string;
}

export interface PartnerAverageMonthlyTests {
  partnerId: string;
  averageTestsGenerated: number;
}

export interface AwbData {
  dhlWaybill: string;
  dhlWaybillDocumentId: string;
}

export interface AwbAddress {
  fullName: string;
  companyName: string;
  street: string;
  city: string;
  postCode: string;
  countryCode: string;
  contactNumber: string;
}

export interface PartnerUserAccount {
  id: string;
  provider: string;
}

export interface Properties {
  key: string;
  value: string;
}

export interface PartnerType {
  id: string;
  type: string;
}

export interface AccountManager {
  id: string;
  name: string;
  email: string;
  phoneNumber?: string;
  applicationId: string;
  subAccountManagers?: string[];
  countries?: string[];
  applications?: string[];
  generatedBy?: string;
  generated?: Date;
}

export interface Subject {
  id: number;
  subjectName: string;
}

export interface Ident {
  id: number;
  name: string;
  ident: string;
}

export interface UserAccount {
  id: string;
  name: string;
  email: string;
  blocked: boolean;
  emailVerified: boolean;
}

export interface UserAccountResponse {
  userAccountId: string;
}

export enum ModalMode {
  Create,
  Edit,
}

export interface Country {
  id: string;
  name: string;
  isoCode3: string;
  notificationProfileId: string;
  notes: string;
  regions: string[];
  productIds: string[];
  bundleIds: string[];
  deactivated?: Status;
  awbModel: string;
  description: string;
  packageDescription: string;
  customerReferenceNumber: string;
  price: number;
  plt: boolean;
  euCountry: boolean;
  onlineReport: boolean;
}

export interface ProductConfiguration {
  id: string;
  productId: string;
  properties: ProductConfigurationProperties[];
  languages: Language[];
  pricing?: ProductConfigurationPricing;
}

export interface BundleConfiguration {
  id: string;
  bundleId: string;
  generated: string;
  generatedBy: string;
}

export interface ProductConfigurationProperties {
  itemKey: string;
  itemValue: string;
}
export interface ProductConfigurationPricing {
  price: number;
  retailPrice: number;
  currency: string;
}

export interface ProductInventory {
  id: string;
  productInventoryItemId: string;
  productId: string;
  partnerId: string;
  kitId: string;
  properties: Properties[];
  documents: string[];
  generated: Timestamp;
  processingStarted: Timestamp;
  processingFinished: Timestamp;
  lastStatus: string;
  lastStatusChanged: string;
  lastStatusChangedBy: string;
  lastStatusChangedReason?: string;
}

export interface DocumentInteraction {
  interaction: string;
  interacted: string;
  interactedBy: string;
}

export interface ResolvedProductInventory extends ProductInventory {
  product: Product;
  partner: Partner;
}

export interface PriceContract {
  currency: string;
  fromDate: Date;
  id: number;
  ident: string;
  identId: number;
  price: number;
  rabat1: number;
  rabat2: number;
  rabat3: number;
  retailPrice: number;
  type: string;
}

export const NO_ID_SELECTED = '---';
export enum PaymentType {
  Partner = 'Partner',
  Customer1 = 'Customer1',
  Customer2 = 'Customer2',
  Online1 = 'Online1',
  Online2 = 'Online2',
}

export const PaymentTypes: PaymentType[] = [
  PaymentType.Partner,
  PaymentType.Customer1,
  PaymentType.Customer2,
  PaymentType.Online1,
  PaymentType.Online2,
];

export enum CustomReportType {
  delayGc = 'Delay GC',
  delayReNumber = 'Delay Ref. Number',
  delayUniReads = 'Delay Uni. Reads',
  delayInconclusive = 'Delay Inconclusive',
  resample = 'Resample',
  resampleFf = 'Resample FF',
  refund = 'Refund',
  notqualifiedByPartner = 'NotQualified-ByPartner',
  notqualifiedByLaboratory = 'NotQualified-ByLaboratory',
  canceled = 'Canceled',
}

export const CustomReportTypes: CustomReportType[] = [
  CustomReportType.delayGc,
  CustomReportType.delayReNumber,
  CustomReportType.delayUniReads,
  CustomReportType.delayInconclusive,
  CustomReportType.resample,
  CustomReportType.resampleFf,
  CustomReportType.refund,
  CustomReportType.notqualifiedByLaboratory,
  CustomReportType.notqualifiedByPartner,
  CustomReportType.canceled,
];

export const GENDERS = ['MALE', 'FEMALE'];

export interface PaginationAndOrdering {
  orderDirection?: SortDirection;
  orderBy?: string;
  top?: number;
  continuationToken?: string;
  filters?: Map<string, string>;
}

export interface Run extends EntityBase {
  name: string;
  failureRate: number;
  notes?: string;
  generated: Status;
  started?: Status;
  finished?: Status;
  kits: KitPositionModel[];
  chipNumber: string;
  sequencer: string;
  newSamples: number;
  site: string;
  signeeId: string;
}

export interface KitPositionModel {
  kitId: string;
  position: number;
}

export class BlobUriResponseModel {
  uri: string;
  blobExists: boolean;
}

export class ExporterState {
  createdTime: Date;
  instanceId: string;
  lastUpdatedTime: Date;
  name: string;
  runtimeStatus: string;
  output: string;
  customStatus: { message: string };
}

export class SagaState {
  completed: boolean;
  errors: string[];
  failed: Date;
  failedReason: string;
  inProgress: boolean;
  processingStarted: Date;
  startedOn: Date;
}

export class DocumentSagaState extends SagaState {
  documents: {
    documentId: string;
    failureReasons: string[];
    isSuccess: boolean;
    productInventoryItem: string;
  }[];
  isVerificationReady: boolean;
  warnings: string[];
}

export class ExportedSagaState extends SagaState {
  productInventoryItemIds: string[];
  productInventoryItems: {
    productInventoryItemId: string;
    marked: boolean;
    timestamp: string;
  }[];
}

export enum RuntimeStatus {
  Running = 'Running',
  Completed = 'Completed',
  Failed = 'Failed',
}

export interface Region {
  country: string;
  regions: string[];
}

export enum StateType {
  Active = 'Active',
  Deactivated = 'Deactivated',
}

export class NotificationProfileModel {
  id: string;
  name: string;
  notes: string;
  sender: string;
  senderName: string;
  templateId: string;
  recipients: RecipientModel[];
  ccRecipients: RecipientModel[];
  bccRecipients: RecipientModel[];
  generated: Status;
  applicationId: string;
}

export class RecipientModel {
  recipient: string;
  recipientName: string;
}

export interface Pagination<T> {
  totalCount: number;
  pagesSize: number;
  collectionSize: number;
  currentPageSettings: {
    pageIndex: number;
    sortSettings: {
      orderBy: string;
      sortDirection: SortDirection;
      pageSize?: number;
    };
    filterSettings?: T;
  };
}

export interface StatePageSettings<T> {
  pageIndex: number;
  pageSize?: number;
  sortSettings: {
    orderBy: string;
    sortDirection: SortDirection;
    pageSize?: number;
  };
  filterSettings?: T;
}

export enum TemplateType {
  Delay = 'Delay',
  Resample = 'Resample',
  ResampleDHL = 'Resample DHL',
  RefundPartnerPayment = 'Refund partner payment',
  RefundCustomerPayment = 'Refund customer payment',
  HighRisk = 'High risk',
  OfficialReport = 'Official report',
  NotQualified = 'NotQualified',
  InsufficientBlood = 'Insufficient Blood',
  Hemolysis = 'Hemolysis',
  DamagedTube = 'Damaged tube',
  Expired = 'Expired',
  CollectionPointClaim = 'Collection point claim',
  CustomerInviteToOnlineReport = 'Customer invite to online report',
  CustomerFormInvite = 'Customer form invite',
  PatientConsent = 'Patient consent',
  Canceled = 'Canceled',
}

export const TemplateTypes = [
  TemplateType.Delay,
  TemplateType.Resample,
  TemplateType.ResampleDHL,
  TemplateType.RefundPartnerPayment,
  TemplateType.RefundCustomerPayment,
  TemplateType.HighRisk,
  TemplateType.OfficialReport,
  TemplateType.NotQualified,
  TemplateType.InsufficientBlood,
  TemplateType.Hemolysis,
  TemplateType.DamagedTube,
  TemplateType.Expired,
  TemplateType.CollectionPointClaim,
  TemplateType.CustomerInviteToOnlineReport,
  TemplateType.CustomerFormInvite,
];

export const LumipeekTemplateTypes = [
  ...TemplateTypes,
  TemplateType.PatientConsent,
];

export interface Template {
  language: string;
  templates: SendGridTemplate[];
}

export interface SendGridTemplate {
  templateType: string;
  templateId: string;
}

export interface Translation {
  language: string;
  translation: string;
}
export interface TranslationTerm {
  context: string;
  term: string;
  translations: Translation[];
}

export interface TranslationProject {
  id: string;
  name: string;
  languages: string[];
  translationTerms: TranslationTerm[];
}

export enum OrderStatus {
  placed = 'Placed',
  confirmed = 'Confirmed',
  canceled = 'Canceled',
  failed = 'Failed',
  paid = 'Paid',
}

export enum AnalysisType {
  NIPT = 'Nipt',
  MONO = 'Mono',
  RH = 'RH',
}

export enum DataInputType {
  NIPT = 'Nipt',
  MONO = 'Mono',
}

export interface Order {
  id: string;
  partnerId: string;
  paymentType: string;
  paymentMethod: string;
  applicationId: string;
  notes: string;
  placed: Status;
  paid: Status;
  lastStatus: OrderStatus;
  lastStatusChanged: Status;
  orderItems: [];
  properties: { itemKey: string; itemValue: string }[];
  canceled: Status;
  failed: Status;
  failureReason: string;
}

export interface AppInfo {
  tag: string;
  hash: string;
}

export interface Invoice {
  applicationId: string;
  id: string;
  issuerId?: string;
  discountValue?: number;
  discountNotes?: string;
  recipientId?: string;
  properties: InvoiceProperties[];
  items?: [];
  invoicePayments: string[];
  paid?: string;
  paidBy?: string;
  laboratoryPrice?: number;
}

export interface ExtendedInvoice extends Invoice {
  payments: InvoicePayment[];
}

export interface InvoiceProperties {
  itemKey: string;
  itemValue: string;
}

export interface InvoicePayment {
  id: string;
  paymentDate: string;
  price: number;
  notes: string;
  generated?: string;
  generatedBy?: string;
  pending?: string;
  pendingBy?: string;
  paid?: string;
}
export interface NIPTSignee {
  id: string;
  name: string;
  signature: string;
}

export interface Coupon extends EntityBase {
  code?: string;
  type?: string;
  generated?: Date;
  validFrom?: Date;
  validTo?: Date;
  redemptionCount?: number;
  revoked?: Date;
  discount?: number;
  discountType?: string;
  productLimit?: number;
  priceLimit?: number;
  couponDomains?: string[];
  products?: string[];
  owner?: string;
}

export interface Domain extends EntityBase {
  domain: string;
  description: string;
  generated: Date;
  generatedBy?: string;
  deactivated?: string;
  deactivatedBy?: string;
}

export interface ApiFilters {
  ids?: string[];
  applicationId?: string;
}

export interface ApiHeaders {
  continuationToken?: string;
  orderBy?: string;
  orderDirection?: SortDirection;
  pagingTop?: number;
  select?: string[];
}

export enum ImportType {
  BgiNipt = 'bgi_nipt',
  ZgNipt = 'zg_nipt',
}
