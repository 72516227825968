import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { DOCUMENT_DATE_TIME_FORMAT } from '@app/app.constants';
import {
  DocumentDescription,
  DocumentType,
  ExcelFileName,
  ExportDialogDataType,
  ExportDialogType,
  ExportType,
  ExportTypeNames,
  ExtendedProductInventoryItem,
  ProductInventoryItemExtensions,
  ProductInventoryItemStatus,
  Sample,
} from '@app/modules/service-data/service-data.types';
import { SampleUtilsService } from '@app/modules/service-data/services/sample-utils.service';
import { ProductInventoryItemState } from '@app/modules/service-data/store/product-inventory-item.state';
import {
  GetSampleAction,
  GetSamplesListAction,
} from '@app/modules/service-data/store/sample.actions';
import { SampleState } from '@app/modules/service-data/store/sample.state';
import { Language, ProductInventory, Properties } from '@core/core.types';
import { AuthenticationService } from '@core/services/authentication.service';
import { AzureBlobService } from '@core/services/azure-blob.service';
import { DocumentsService } from '@core/services/documents.service';
import { ExportersUtilsService } from '@core/services/exporters-utils.service';
import { ExportersService } from '@core/services/exporters.service';
import {
  ArchiveDocumentAction,
  LoadUploadedDocumentsAction,
} from '@core/store/documents/documents.actions';
import { LanguageState } from '@core/store/languages/languages.state';
import {
  LoadPartnerById,
  LoadPartnersByIds,
} from '@core/store/partners/partners.actions';
import { PartnersState } from '@core/store/partners/partners.state';
import { ProductsListState } from '@core/store/products/products.state';
import { CoreUtilsService } from '@core/utils/core-utils.service';
import { generatePassword, Util } from '@core/utils/core.util';
import { HttpUtils } from '@core/utils/http-utils';
import { NbDialogService, NbToastrService } from '@nebular/theme';
import { Store } from '@ngxs/store';
import { ProgressData } from '@shared/components/progress/progress-dialog.component';
import { TreatmentFilters as TreatmentFiltersType } from '@treatments/treatment.types';
import FileSaver from 'file-saver';
import { combineLatest, forkJoin, from, Observable, Subject } from 'rxjs';
import { of } from 'rxjs/internal/observable/of';
import { debounceTime, delay, first, map, switchMap } from 'rxjs/operators';
import { ProductInventoryItemDetailsComponent } from '../components/product-inventory-item/product-inventory-item-details/product-inventory-item-details.component';
import { ProductInventoryItemResultsComponent } from '../components/product-inventory-item/product-inventory-item-results/product-inventory-item-results.component';
import {
  AssignDocumentToProductInventoryItemAction,
  GetProductInventoryItemListAction,
} from '../store/product-intentory-item.actions';
import { ProductInventoryItemFilters } from './product-inventory-item.service';

@Injectable({
  providedIn: 'root',
})
export class ProductInventoryItemUtilsService {
  constructor(
    private store: Store,
    private documentsService: DocumentsService,
    private sampleUtilsService: SampleUtilsService,
    private exportersService: ExportersService,
    private exportersUtilsService: ExportersUtilsService,
    private authService: AuthenticationService,
    private coreUtils: CoreUtilsService,
    private datePipe: DatePipe,
    private nbDialogService: NbDialogService,
    private toast: NbToastrService,
    private azureBlobService: AzureBlobService
  ) {}

  public assignUnclassifiedDocumentToPii(
    document: {
      guid: string;
      fileName: string;
      kitId: string;
      data: string;
      documentProperties: Properties[];
    },
    pii: ExtendedProductInventoryItem
  ) {
    return this.findRedrawBarcode(
      document.kitId,
      Util.getAnalysisTypeFromName(document.fileName)
    ).pipe(
      switchMap((kitId: string) => {
        document.fileName = this.replaceFilenameBarcode(
          document.fileName,
          kitId
        );

        return this.documentsService.getDocumentReadUri(document.guid);
      }),
      switchMap((documentUrl: string) =>
        this.documentsService.getDocumentContent(documentUrl)
      ),
      switchMap((documentContent: Blob) =>
        Util.getBase64ImageFromUrl(documentContent)
      ),
      switchMap((base64Content: string) =>
        from(
          this.azureBlobService.uploadFileFromByteArray(
            document.fileName,
            base64Content
          )
        ).pipe(
          switchMap(() =>
            this.store.dispatch(new ArchiveDocumentAction(document.guid))
          ),
          switchMap(() => {
            document.guid = Util.CreateGuid();
            document.data = base64Content;
            return this.addDocumentToPii(document, pii);
          })
        )
      )
    );
  }

  public addDocumentsToPiis(
    documentList: {
      guid: string;
      fileName: string;
      kitId: string;
      data: string;
      documentProperties: Properties[];
    }[]
  ) {
    return this.store
      .dispatch(
        new GetSamplesListAction(
          { kitIds: documentList.map((doc) => doc.kitId) },
          {},
          null,
          false
        )
      )
      .pipe(
        switchMap(() =>
          this.store
            .select(SampleState.getSamplesByKitIds)
            .pipe(
              map((getByIds) => getByIds(documentList.map((doc) => doc.kitId)))
            )
        ),
        first(),
        switchMap((samples) => {
          const piiIds = samples
            .map((sample) => sample.productInventoryItems)
            .flat(1);
          return this.store
            .dispatch(
              new GetProductInventoryItemListAction({ ids: piiIds }, null, true)
            )
            .pipe(
              switchMap(() =>
                this.store
                  .select(
                    ProductInventoryItemState.getProductInventoryItemsByIds
                  )
                  .pipe(map((getByIds) => getByIds(piiIds)))
              ),
              first(),
              switchMap((productInventoryItems) =>
                this.getExtendedProductInventoryItemList(
                  productInventoryItems,
                  {
                    partner: false,
                    product: true,
                  }
                )
              )
            );
        }),
        first(),
        switchMap((extendedPiis) =>
          forkJoin(
            documentList.map((document) =>
              this.documentsService
                .postGenerateDocument(
                  `.${Util.GetExtensionFromFileName(document.fileName)}`,
                  // Add analysis type to file name
                  document.fileName,
                  Util.GetBase64BytesLength(document.data),
                  document.guid,
                  document.documentProperties
                )
                .pipe(
                  switchMap(() => {
                    const analysisType = Util.getAnalysisTypeFromName(
                      document.fileName
                    );
                    const epii = extendedPiis.find(
                      (epii) =>
                        epii.kitId === document.kitId &&
                        epii.product.analysisType === analysisType
                    );

                    if (!epii) {
                      this.toast.danger(
                        `Couldn't find PII with Analysis type ${analysisType}`,
                        "Couldn't find PII."
                      );
                      throw new Error("Couldn't find PII");
                    }
                    return this.store.dispatch(
                      new AssignDocumentToProductInventoryItemAction(
                        epii.id,
                        document.guid
                      )
                    );
                  })
                )
            )
          )
        ),
        switchMap(() =>
          this.store.dispatch(
            new LoadUploadedDocumentsAction(
              {
                ids: documentList.map((document) => document.guid),
              },
              true
            )
          )
        )
      );
  }

  getExtendedProductInventoryItem(
    pii: ProductInventory,
    appends: ProductInventoryItemExtensions = {
      partner: true,
      product: true,
      sample: null,
    }
  ): Observable<ExtendedProductInventoryItem> {
    if (!pii) {
      return of(null);
    }

    return forkJoin([
      appends.partner
        ? this.store.dispatch(new LoadPartnerById(pii.partnerId))
        : of(null),
      appends.sample
        ? this.store.dispatch(
            new GetSampleAction(
              pii.properties.find(
                (property) => property.key === 'SampleId'
              ).value,
              false,
              false
            )
          )
        : of(null),
    ]).pipe(
      switchMap(() =>
        combineLatest([
          of(pii),
          appends.product
            ? this.store
                .select(ProductsListState.getProduct)
                .pipe(map((getProduct) => getProduct(pii.productId)))
            : of(null),
          appends.partner
            ? this.store
                .select(PartnersState.getPartnerById)
                .pipe(map((getPartnerById) => getPartnerById(pii.partnerId)))
            : of(null),
          appends.sample
            ? this.store.select(SampleState.getSampleById).pipe(
                map((getSampleById) =>
                  getSampleById(
                    pii.properties.find(
                      (property) => property.key === 'SampleId'
                    ).value
                  )
                ),
                debounceTime(100),
                switchMap((sample) =>
                  this.sampleUtilsService.getExtendedSample(
                    sample,
                    appends.sample
                  )
                )
              )
            : of(null),
        ])
      ),
      map(([pii, product, partner, sample]) => ({
        ...pii,
        product,
        partner,
        sample,
      }))
    );
  }

  getExtendedProductInventoryItemList(
    piiList: ProductInventory[],
    appends: ProductInventoryItemExtensions = {
      partner: true,
      product: true,
      sample: null,
    }
  ): Observable<ExtendedProductInventoryItem[]> {
    if (!piiList.length) {
      return of([]);
    }

    const partnerIds: string[] = [];
    if (appends.partner) {
      piiList.forEach((pii) => {
        partnerIds.push(pii.partnerId);
      });
    }

    const sampleIds: string[] = [];
    if (appends.sample) {
      piiList.forEach((pii) => {
        sampleIds.push(
          pii.properties.find((property) => property.key === 'SampleId').value
        );
      });
    }

    return forkJoin({
      samples: appends.sample
        ? this.store.dispatch(
            new GetSamplesListAction({ ids: sampleIds }, {}, null, false)
          )
        : of(null),
      partners: appends.partner
        ? this.store.dispatch(new LoadPartnersByIds(partnerIds))
        : of(null),
    }).pipe(
      switchMap(() =>
        combineLatest(
          piiList.map((pii) =>
            this.getExtendedProductInventoryItem(pii, appends)
          )
        )
      )
    );
  }

  getPiisDataWithPartnerLanguage(
    piis: ExtendedProductInventoryItem[]
  ): Observable<[ExtendedProductInventoryItem, string][]> {
    if (!piis.length) {
      return of([]);
    }
    return forkJoin(
      piis.map((pii) =>
        this.store.select(LanguageState.getLanguageByName).pipe(
          map((filterById) => filterById(pii.partner.languages[0])),
          first((lng: Language) => !!lng),
          map((lng: Language) => {
            const language = lng.isoCode.includes('-')
              ? lng.isoCode.split('-')[0]
              : lng.isoCode;
            return [pii, language] as [ExtendedProductInventoryItem, string];
          })
        )
      )
    );
  }

  calculatePiiProgress(pii: ProductInventory) {
    if (
      pii.lastStatus === ProductInventoryItemStatus.processingFinished ||
      pii.lastStatus === ProductInventoryItemStatus.canceled ||
      pii.lastStatus === ProductInventoryItemStatus.processingFailed
    ) {
      return 3;
    } else if (pii.documents.length > 0) {
      return 2;
    }
    if (pii.lastStatus === ProductInventoryItemStatus.processingStarted) {
      return 1;
    }
    return 0;
  }

  public openDetails(piiId: string) {
    this.nbDialogService.open(ProductInventoryItemDetailsComponent, {
      context: { piiId },
    });
  }

  public openResults(piiIds: string[]) {
    this.nbDialogService.open(ProductInventoryItemResultsComponent, {
      context: { piiIds },
    });
  }

  buildHttpParams(params: ProductInventoryItemFilters) {
    let httpParams = HttpUtils.buildHttpParams(params);

    if (params.documentIds?.length) {
      httpParams = httpParams.set(
        TreatmentFiltersType.DocumentsIds,
        params.documentIds.join(',')
      );
    }

    if (params.kitIds?.length) {
      httpParams = httpParams.set(
        TreatmentFiltersType.KitIds,
        params.kitIds.join(',')
      );
    }

    if (params.productIds?.length) {
      httpParams = httpParams.set(
        TreatmentFiltersType.ProductIds,
        params.productIds.join(',')
      );
    }

    if (params.countries?.length) {
      httpParams = httpParams.set('countries', params.countries.join(','));
    }

    return httpParams;
  }

  public niptExportRequestForms(ids: string[]): Observable<boolean> {
    const password = generatePassword();

    const progressSubject = this.exportersUtilsService.openProgressDialog(
      ExportTypeNames.RequestForms,
      password
    );

    if (!ids) {
      return of(false);
    }

    return this.exportRequestForms(ids, progressSubject, password);
  }

  public async exportNiptTests(
    exportDialogType: ExportDialogType,
    exportType: ExportTypeNames,
    ids?: string[],
    filters?: { [key: string]: string }
  ) {
    const password =
      exportType === ExportTypeNames.Insurance ||
      exportType === ExportTypeNames.RequestForms
        ? generatePassword()
        : undefined;

    const progressSubject = this.exportersUtilsService.openProgressDialog(
      exportType,
      password
    );

    let success = true;

    if (exportDialogType === ExportDialogType.Selected) {
      if (exportType === ExportTypeNames.BGIInformationSheet) {
        success = !!(await this.exportersUtilsService.exportSelected(
          ExportDialogDataType.Pii,
          ids,
          ExportType.BGIInformationSheet,
          ExcelFileName.BGIInformationSheet,
          true,
          false
        ));
      } else if (exportType === ExportTypeNames.Insurance) {
        success = !!(await this.exportersUtilsService.exportSelected(
          ExportDialogDataType.Pii,
          ids,
          ExportType.Insurance,
          ExcelFileName.Insurance,
          true,
          false,
          password
        ));
      } else if (exportType === ExportTypeNames.ReportExport) {
        const anonymous = !(await this.authService
          .hasRequiredPermission$('pii.export.reportExport.notAnonymous')
          .pipe(first())
          .toPromise());

        success = !!(await this.exportersUtilsService.exportSelected(
          ExportDialogDataType.Pii,
          ids,
          ExportType.Report,
          ExcelFileName.ReportExport,
          true,
          false,
          undefined,
          anonymous
        ));
      } else if (exportType === ExportTypeNames.Operations) {
        success = !!(await this.exportersUtilsService.exportSelected(
          ExportDialogDataType.Pii,
          ids,
          ExportType.Operations,
          ExcelFileName.Operations
        ));
      }
    } else {
      if (exportType === ExportTypeNames.BGIInformationSheet) {
        success = !!(await this.exportersUtilsService.export(
          filters,
          ExportType.BGIInformationSheet,
          ExcelFileName.BGIInformationSheet,
          true,
          false,
          password
        ));
      } else if (exportType === ExportTypeNames.Insurance) {
        success = !!(await this.exportersUtilsService.export(
          filters,
          ExportType.Insurance,
          ExcelFileName.Insurance,
          true,
          false,
          password
        ));
      } else if (exportType === ExportTypeNames.ReportExport) {
        const anonymous = !(await this.authService
          .hasRequiredPermission$('pii.export.reportExport.notAnonymous')
          .pipe(first())
          .toPromise());

        success = !!(await this.exportersUtilsService.export(
          filters,
          ExportType.Report,
          ExcelFileName.ReportExport,
          true,
          false,
          undefined,
          anonymous
        ));
      } else if (exportType === ExportTypeNames.Operations) {
        success = !!(await this.exportersUtilsService.export(
          filters,
          ExportType.Operations,
          ExcelFileName.Operations
        ));
      } else {
        this.coreUtils.showErrowMessage(
          'Please provide required information for export.'
        );
      }
    }

    progressSubject.next({ value: 100, status: 'Done', success });

    return success;
  }

  public replaceFilenameBarcode(fileName, kitId) {
    const fileNameSplit = fileName.split('-');
    fileNameSplit[0] = kitId;
    return fileNameSplit.join('-');
  }

  public findRedrawBarcode(
    kitId: string,
    analysisType: string
  ): Observable<string> {
    return this.findRedrawBarcodeRecursion(
      kitId.replace(/R/g, ''),
      analysisType,
      (kitId.match(/R/g) || []).length
    );
  }

  private findRedrawBarcodeRecursion(
    barcode: string,
    analysisType: string,
    loop = 0
  ): Observable<string> {
    if (loop > 0) {
      return this.store
        .dispatch(
          new GetSamplesListAction({ kitIds: [barcode] }, {}, null, false)
        )
        .pipe(
          switchMap(() =>
            this.store
              .select(SampleState.getSampleByKitId)
              .pipe(map((getSampleByKitId) => getSampleByKitId(barcode)))
          )
        )
        .pipe(
          first(),
          switchMap((sample: Sample) =>
            this.store
              .dispatch(new GetSampleAction(sample?.nextSampleId, false, false))
              .pipe(
                switchMap(() =>
                  this.store
                    .select(SampleState.getSampleById)
                    .pipe(
                      map((getSampleById) =>
                        getSampleById(sample?.nextSampleId)
                      )
                    )
                )
              )
          ),
          first(),
          switchMap((nextSample: Sample) =>
            this.store
              .dispatch(
                new GetProductInventoryItemListAction(
                  { ids: nextSample.productInventoryItems },
                  null,
                  false
                )
              )
              .pipe(
                switchMap(() =>
                  this.store
                    .select(
                      ProductInventoryItemState.getProductInventoryItemsByIds
                    )
                    .pipe(
                      map((getPiisByIds) =>
                        getPiisByIds(nextSample.productInventoryItems)
                      ),
                      first(),
                      switchMap((piis: ProductInventory[]) =>
                        this.getExtendedProductInventoryItemList(piis, {
                          product: true,
                        }).pipe(
                          first(),
                          switchMap(
                            (extendedPiis: ExtendedProductInventoryItem[]) => {
                              const correctPii = extendedPiis.find(
                                (pii: ExtendedProductInventoryItem) =>
                                  pii.product?.analysisType === analysisType
                              );

                              if (
                                correctPii.lastStatusChangedReason &&
                                correctPii.lastStatusChangedReason ===
                                  `${DocumentType.NotQualified} - ${DocumentDescription.ByPartner}`
                              ) {
                                loop++;
                              }

                              return this.findRedrawBarcodeRecursion(
                                nextSample.kitId,
                                analysisType,
                                loop - 1
                              );
                            }
                          ),
                          map((newBarcode) => newBarcode ?? barcode + 'R')
                        )
                      )
                    )
                )
              )
          )
        );
    }

    return of(barcode);
  }

  private exportRequestForms(
    piiIds: string[],
    progressSubject: Subject<ProgressData> = new Subject(),
    password = ''
  ): Observable<boolean> {
    if (!piiIds && !piiIds.length) {
      return of(false);
    }

    const expectedFiles = piiIds.length + 1;

    progressSubject.next({
      value: Math.round((1 / expectedFiles) * 100),
      status: 'Exporting Request forms',
    });

    return this.store
      .dispatch(
        new GetProductInventoryItemListAction({ ids: piiIds }, null, true)
      )
      .pipe(
        delay(300),
        switchMap(() =>
          this.store
            .select(ProductInventoryItemState.getProductInventoryItemsByIds)
            .pipe(
              map((filterByIds) => filterByIds(piiIds)),
              switchMap((piis: ProductInventory[]) =>
                this.getExtendedProductInventoryItemList(piis, {
                  product: true,
                  sample: { doctor: true, serviceData: { bundles: true } },
                })
              ),
              first(),
              switchMap((piis: ExtendedProductInventoryItem[]) =>
                combineLatest([
                  ...this.getRequestForms(piis, progressSubject, expectedFiles),
                ]).pipe(
                  switchMap(
                    (
                      requestForms: {
                        content: string;
                        filename: string;
                      }[]
                    ) => {
                      if (requestForms && requestForms.length) {
                        return this.exportersService.generateRequestForm(
                          requestForms,
                          password
                        );
                      }
                    }
                  )
                )
              )
            )
        ),
        map((zip: { data: string }) => {
          const dateString = this.datePipe.transform(
            new Date(),
            DOCUMENT_DATE_TIME_FORMAT
          );

          FileSaver.saveAs(
            new Blob([Util.ConvertBase64ToBlob(zip?.data)]),
            `export ${dateString}.zip`
          );

          progressSubject.next({ value: 100, status: 'Done', success: true });

          return true;
        })
      );
  }

  private getRequestForms(
    piis: ExtendedProductInventoryItem[],
    progressSubject: Subject<ProgressData>,
    expectedFiles: number
  ): Observable<{
    content: string;
    filename: string;
  }>[] {
    return piis.map((pii: ExtendedProductInventoryItem, index: number) => {
      if (pii.sample?.previousSampleId) {
        return this.getPreviousKitId(pii.sample.previousSampleId).pipe(
          map((previousSampleKitId: string) => {
            const requestForm =
              this.exportersUtilsService.getRequestFormTemplate(
                pii,
                previousSampleKitId,
                { index }
              );

            progressSubject.next({
              value: Math.round(((index + 1) / expectedFiles) * 100),
              status: 'Exporting Request forms',
            });

            return requestForm;
          })
        );
      } else {
        const requestForm = this.exportersUtilsService.getRequestFormTemplate(
          pii,
          null,
          { index }
        );

        progressSubject.next({
          value: Math.round(((index + 1) / expectedFiles) * 100),
          status: 'Exporting Request forms',
        });

        return of(requestForm);
      }
    });
  }

  private getPreviousKitId(previousSampleId: string): Observable<string> {
    return this.store.dispatch(new GetSampleAction(previousSampleId)).pipe(
      switchMap(() =>
        this.store.select(SampleState.getSampleById).pipe(
          map((getSampleById) => {
            return getSampleById(previousSampleId)?.kitId;
          })
        )
      )
    );
  }

  private assignDocumentToPii(
    document: {
      guid: string;
      fileName: string;
      kitId: string;
      data: string;
      documentProperties: Properties[];
    },
    extendedPiis: ExtendedProductInventoryItem[]
  ) {
    return this.documentsService
      .postGenerateDocument(
        `.${Util.GetExtensionFromFileName(document.fileName)}`,
        // Add analysis type to file name
        document.fileName,
        Util.GetBase64BytesLength(document.data),
        document.guid,
        document.documentProperties
      )
      .pipe(
        switchMap(() =>
          this.store.dispatch(
            new AssignDocumentToProductInventoryItemAction(
              extendedPiis.find(
                (epii) =>
                  epii.kitId === document.kitId &&
                  epii.product.analysisType ===
                    Util.getAnalysisTypeFromName(document.fileName)
              ).id,
              document.guid
            )
          )
        )
      );
  }

  private addDocumentToPii(
    document: {
      guid: string;
      fileName: string;
      kitId: string;
      data: string;
      documentProperties: Properties[];
    },
    pii: ExtendedProductInventoryItem
  ) {
    return this.assignDocumentToPii(document, [pii]).pipe(
      switchMap(() =>
        this.store.dispatch(
          new LoadUploadedDocumentsAction({ ids: [document.guid] }, true)
        )
      )
    );
  }
}
