import { Injectable } from '@angular/core';
import { LOCAL_STORAGE_KEYS } from '@app/app.constants';
import { ProductInventoryItemUtilsService } from '@app/modules/service-data/services/product-inventory-item-utils.service';
import { GetProductInventoryItemListAction } from '@app/modules/service-data/store/product-intentory-item.actions';
import { ProductInventoryItemState } from '@app/modules/service-data/store/product-inventory-item.state';
import { AnalysisType, Properties } from '@core/core.types';
import { Logger } from '@core/services/logger.service';
import { ReportsService } from '@core/services/reports.service';
import { Store } from '@ngxs/store';
import {
  Document,
  DocumentInteraction,
  DocumentStatus,
  ExtendedDocument,
  NonOfficialTreatmentDocumentReportTypes,
  TreatmentDocumentKeywords,
} from '@treatments/treatment.types';
import moment from 'moment';
import { Observable, combineLatest } from 'rxjs';
import { of } from 'rxjs/internal/observable/of';
import { first, map, switchMap } from 'rxjs/operators';
import { Util, getFileNameWithoutExtension } from '../@core/utils/core.util';
import { DocumentExtensions } from '../service-data/service-data.types';

@Injectable({
  providedIn: 'root',
})
export class DocumentsUtilsService {
  private readonly log = new Logger(this.constructor.name);

  constructor(
    private reportsService: ReportsService,
    private productInventoryItemUtils: ProductInventoryItemUtilsService,
    private store: Store
  ) {}

  public isBGIDocument(document: Document) {
    return (
      !document.fileName.includes('-HR-') && !document.fileName.includes('-HK-')
    );
  }

  public getCheckedDocuments(): string[] {
    return (
      JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.checkedDocuments)) ??
      []
    );
  }

  public isDocumentChecked(id) {
    return this.getCheckedDocuments().includes(id);
  }

  public getExtendedDocuments(
    documents: Document[],
    appends: DocumentExtensions = {
      pii: null,
    }
  ): Observable<ExtendedDocument[]> {
    return this.store
      .dispatch(
        new GetProductInventoryItemListAction(
          { documentIds: documents.map((document) => document.id) },
          null,
          true
        )
      )
      .pipe(
        switchMap(() =>
          combineLatest(
            documents.map((document) =>
              combineLatest([
                of(document),
                appends.pii
                  ? this.store
                      .select(
                        ProductInventoryItemState.getProductInventoryItemByDocumentId
                      )
                      .pipe(
                        map((getById) => getById(document.id)),
                        switchMap((pii) =>
                          this.productInventoryItemUtils.getExtendedProductInventoryItem(
                            pii,
                            appends.pii
                          )
                        )
                      )
                  : of(null),
              ])
            )
          )
        ),
        map((items) =>
          items.map(([document, productInventoryItem]) => ({
            ...document,
            productInventoryItem,
          }))
        )
      );
  }

  public getLastDocumentInteractionByType(
    interactions: DocumentInteraction[],
    interactionType: DocumentStatus.approved | DocumentStatus.disapproved
  ): DocumentInteraction | null {
    if (interactions && interactions.length) {
      const sortedInteractions = [...interactions]
        .filter(
          ({ interaction }) =>
            interaction !== DocumentStatus.downloaded &&
            interaction !== DocumentStatus.uploaded &&
            interaction !== DocumentStatus.archived
        )
        .sort(
          (a, b) => moment(b.interacted).unix() - moment(a.interacted).unix()
        );
      const [lastInteraction] = sortedInteractions;
      if (lastInteraction && lastInteraction.interaction === interactionType) {
        return lastInteraction;
      }
    }
    return null;
  }

  public uploadBase64Docx(
    fileName: string,
    data: string,
    documentProperties: Properties[] = [],
    analysisType: AnalysisType
  ) {
    this.log.debug('upload docx', fileName);

    fileName = Util.addAnalysisTypeToName(fileName, analysisType);

    let reportType: string;

    if (fileName.endsWith('.pdf')) {
      reportType = 'pdf';
    }
    if (fileName.endsWith('.docx') || fileName.endsWith('.doc')) {
      reportType = 'docx';
    }

    // Extract barcode from filename
    let kitId = fileName.split('-')[0];

    // Replace P with B -> so P documents are not unclassified
    // Remove D at the end for twins
    kitId = kitId.replace('P', 'B').replace('D', '').replace('V', '');

    // Check if barcode belongs to a redraw treatment
    return this.productInventoryItemUtils
      .findRedrawBarcode(kitId, analysisType)
      .pipe(
        switchMap((kitId) => {
          // Replace redraw KitId barcode with redrawn KitId
          fileName = this.productInventoryItemUtils.replaceFilenameBarcode(
            fileName,
            kitId
          );
          return combineLatest([
            of(data),
            this.reportsService.provisionReport({
              Data: data,
              FileName: getFileNameWithoutExtension(fileName),
              ReportType: reportType,
            }),
          ]);
        }),
        map(([data, fileName]) => {
          const document = {
            guid: Util.CreateGuid(),
            kitId,
            fileName: fileName + '.pdf',
            data,
            documentProperties: [
              ...documentProperties,
              {
                key: 'DocumentType',
                value: Util.getReportTypeFromName(fileName),
              },
              {
                key: 'AnalysisType',
                value: Util.getAnalysisTypeFromName(fileName),
              },
            ],
          };

          if (Util.getDocumentDescriptionFromName(fileName)) {
            document.documentProperties.push({
              key: 'DocumentDescription',
              value: Util.getDocumentDescriptionFromName(fileName),
            });
          }

          if (Util.getDocumentReason(fileName)) {
            document.documentProperties.push({
              key: 'DocumentReason',
              value: Util.getDocumentReason(fileName),
            });
          }

          return document;
        }),
        switchMap((document) =>
          this.productInventoryItemUtils.addDocumentsToPiis([document])
        )
      );
  }

  private uploadDocument(
    file,
    documentProperties: Properties[],
    analysisType: AnalysisType
  ): Observable<{
    guid: string;
    fileName: string;
    kitId: string;
    data: string;
    documentProperties: Properties[];
  }> {
    let newFilename = this.transformBGIFilename(file);

    newFilename = Util.addAnalysisTypeToName(newFilename, analysisType);

    let reportType: string;

    if (file.name.endsWith('.pdf')) {
      reportType = 'pdf';
    }
    if (file.name.endsWith('.docx') || file.name.endsWith('.doc')) {
      reportType = 'docx';
    }

    // Extract barcode from filename
    let kitId = newFilename.split('-')[0];

    // Replace P with B -> so P documents are not unclassified
    // Remove D at the end for twins
    kitId = kitId.replace('P', 'B').replace('D', '').replace('V', '');

    // Check if barcode belongs to a redraw treatment
    return this.productInventoryItemUtils
      .findRedrawBarcode(kitId, Util.getAnalysisTypeFromName(newFilename))
      .pipe(
        first(),
        switchMap((newKitId) => {
          kitId = newKitId;
          // Replace redraw KitId barcode with redrawn KitId
          newFilename = this.productInventoryItemUtils.replaceFilenameBarcode(
            newFilename,
            kitId
          );

          return combineLatest([
            of(newFilename),
            Util.getBase64ImageFromFile(file),
          ]);
        }),
        switchMap(([newFilename, data]) =>
          combineLatest([
            of(data),
            this.reportsService.provisionReport({
              Data: data,
              FileName: getFileNameWithoutExtension(newFilename),
              ReportType: reportType,
            }),
          ])
        ),
        map(([data, fileName]) => {
          const document = {
            guid: Util.CreateGuid(),
            kitId,
            fileName: fileName + '.pdf',
            data,
            documentProperties: [
              ...documentProperties,
              {
                key: 'DocumentType',
                value: Util.getReportTypeFromName(fileName),
              },
              {
                key: 'AnalysisType',
                value: Util.getAnalysisTypeFromName(fileName),
              },
            ],
          };

          if (Util.getDocumentDescriptionFromName(fileName)) {
            document.documentProperties.push({
              key: 'DocumentDescription',
              value: Util.getDocumentDescriptionFromName(fileName),
            });
          }

          if (Util.getDocumentReason(fileName)) {
            document.documentProperties.push({
              key: 'DocumentReason',
              value: Util.getDocumentReason(fileName),
            });
          }

          return document;
        })
      );
  }

  uploadDocumentsMass(
    files: File[],
    documentProperties: Properties[],
    analysisType: AnalysisType
  ) {
    return combineLatest(
      files.map((file) =>
        this.uploadDocument(file, documentProperties, analysisType)
      )
    ).pipe(
      switchMap((documentsList) =>
        this.productInventoryItemUtils.addDocumentsToPiis(documentsList)
      )
    );
  }

  public transformBGIFilename(file: File) {
    if (file.name.toLowerCase().includes('_geneplanet')) {
      this.log.info('BGI report naming');

      // Replace "re-sample/resampling" with "resample", and "high-risk" with "high risk", so it doesn't mess with filename transformation
      const fileName = file.name
        .replace(
          new RegExp(TreatmentDocumentKeywords.resample1, 'gi'),
          TreatmentDocumentKeywords.resample2
        )
        .replace(
          new RegExp(TreatmentDocumentKeywords.resample3, 'gi'),
          TreatmentDocumentKeywords.resample2
        )
        .replace(
          new RegExp(TreatmentDocumentKeywords.highRisk2, 'gi'),
          TreatmentDocumentKeywords.highRisk1
        );

      const withoutExtension = fileName.substring(0, fileName.length - 4);

      const gpIndex = withoutExtension.toLowerCase().indexOf('_geneplanet');

      const beforeGp = withoutExtension.substring(0, gpIndex);
      const afterGp = withoutExtension.substring(gpIndex);

      const afterGpSplit = afterGp.split('-');

      // last index is always type, therefore pop()
      let type =
        afterGpSplit.length > 1
          ? afterGpSplit.pop().toLowerCase()
          : 'official report';

      // If Unknown type, we assume it's a type of "High risk"
      if (
        !NonOfficialTreatmentDocumentReportTypes.some((reportType) =>
          type.includes(reportType)
        ) &&
        !type.includes(TreatmentDocumentKeywords.official)
      ) {
        type = `${TreatmentDocumentKeywords.highRisk1}-${type}`;
      }
      const fileExtension = fileName.split('.').pop();

      return `${beforeGp}${afterGpSplit.join('-')}-${type}.${fileExtension}`
        .split('_')
        .join('-');
    }
    return file.name;
  }
}
