<div class="row form-group">
  <div class="col-6">
    <div class="row align-items-center">
      <div class="col-4">
        <label for="expectedConfinementDate">{{
          'serviceData.form.expectedConfinementDate' | translate
        }}</label>
      </div>
      <div class="col-8">
        <input
          fullWidth
          nbInput
          formControlName="expectedConfinementDate"
          id="expectedConfinementDate"
          appInputValidationStatus
          [nbDatepicker]="confinementDatepicker"
          [placeholder]="'serviceData.form.placeholders.dateFormat' | translate"
          data-cy="due-date-input"
        />
        <nb-datepicker
          [min]="minDate"
          [max]="maxDate"
          [date]="dueDateStartDate"
          [format]="DATEPICKER_DATE_FORMAT"
          #confinementDatepicker
        ></nb-datepicker>
      </div>
    </div>
    <div class="row justify-content-end">
      <div class="col-8">
        <app-validation-error
          for="expectedConfinementDate"
          translations="validation.generic"
        ></app-validation-error>
      </div>
    </div>
  </div>
  <div class="col-6">
    <div class="row align-items-center" formGroupName="gestationAge">
      <div class="col-4">
        <label>{{ 'serviceData.form.gestationAge' | translate }}</label>
      </div>
      <div class="col-8">
        <div class="row">
          <div class="col-6">
            <input
              fullWidth
              nbInput
              type="number"
              formControlName="week"
              id="gestationWeek"
              appInputValidationStatus
              [placeholder]="
                'serviceData.form.placeholders.gestationWeek' | translate
              "
              [readonly]="
                form.get('isGestationAgeAutomaticallyCalculated').value
              "
              data-cy="gestation-week-input"
            />
          </div>
          <div class="col-6">
            <input
              fullWidth
              nbInput
              type="number"
              formControlName="day"
              id="gestationDay"
              appInputValidationStatus
              [placeholder]="
                'serviceData.form.placeholders.gestationDay' | translate
              "
              [readonly]="
                form.get('isGestationAgeAutomaticallyCalculated').value
              "
              data-cy="gestation-day-input"
            />
          </div>
        </div>
      </div>
      <div class="row justify-content-end p-0 m-0">
        <div class="col-4">
          <app-validation-error
            for="week"
            translations="validation.generic"
          ></app-validation-error>
        </div>
        <div class="col-4">
          <app-validation-error
            for="day"
            translations="validation.generic"
          ></app-validation-error>
        </div>
      </div>
    </div>
    <div class="row mt-1">
      <div class="col-8 offset-4">
        <nb-checkbox formControlName="isGestationAgeAutomaticallyCalculated" data-cy="auto-calculate-checkbox">
          {{ 'serviceData.form.autoCalculate' | translate }}
        </nb-checkbox>
      </div>
    </div>
  </div>
</div>

<div class="row form-group">
  <div class="col-6">
    <div class="row align-items-center">
      <div class="col-4">
        <label for="pregnancyType">{{
          'serviceData.form.pregnancyType' | translate
        }}</label>
      </div>
      <div class="col-8">
        <nb-select
          class="w-100"
          id="pregnancyType"
          appInputValidationStatus
          formControlName="pregnancyType"
          [placeholder]="
            'serviceData.form.placeholders.pregnancyType' | translate
          "
          data-cy="pregnancy-type-select"
        >
          @for (type of Object.keys(PregnancyType); track type) {
            <nb-option [value]="PregnancyType[type]">{{
              PregnancyType[type]
            }}</nb-option>
          }
        </nb-select>
      </div>
    </div>
    <div class="row justify-content-end">
      <div class="col-8">
        <app-validation-error
          for="pregnancyType"
          translations="validation.generic"
        ></app-validation-error>
      </div>
    </div>
  </div>
  <div class="col-6">
    @if (form.get('pregnancyType').value === PregnancyType.twins) {
      <div class="row align-items-center">
        <div class="col-4">
          <label for="chorionicityType">{{
            'serviceData.form.chorionicityType' | translate
          }}</label>
        </div>
        <div class="col-8">
          <nb-select
            class="w-100"
            appInputValidationStatus
            formControlName="chorionicityType"
            id="chorionicityType"
            [placeholder]="
              'serviceData.form.placeholders.chorionicityType' | translate
            "
            data-cy="chorionicity-type-select"
          >
            @for (type of Object.keys(ChorinicityType); track type) {
              <nb-option [value]="type">{{ ChorinicityType[type] }}</nb-option>
            }
          </nb-select>
        </div>
      </div>
      <div class="row justify-content-end">
        <div class="col-8">
          <app-validation-error
            for="chorionicityType"
            translations="validation.generic"
          ></app-validation-error>
        </div>
      </div>
    }
  </div>
</div>

<ng-container formGroupName="inVitro">
  <div class="row form-group">
    <div class="col-6">
      <div class="row align-items-center">
        <div class="col-4">
          <label for="isInVitroFertilized">{{
            'serviceData.form.isInVitroFertilized' | translate
          }}</label>
        </div>
        <div class="col-8">
          <nb-radio-group
            id="isInVitroFertilized"
            name="isInVitroFertilized"
            class="radio-toolbar"
            formControlName="isInVitroFertilized"
            data-cy="in-vitro-fertilized-rg"
          >
            <nb-radio [value]="true">{{ 'common.yes' | translate }}</nb-radio>
            <nb-radio [value]="false">{{ 'common.no' | translate }}</nb-radio>
          </nb-radio-group>
        </div>
      </div>
      <div class="row justify-content-end">
        <div class="col-8">
          <app-validation-error
            for="isInVitroFertilized"
            translations="validation.generic"
          ></app-validation-error>
        </div>
      </div>
    </div>
    <div class="col-6">
      @if (form.get('inVitro.isInVitroFertilized').value) {
        <div class="row align-items-center">
          <div class="col-4">
            <label for="vitroType">{{
              'serviceData.form.vitroType' | translate
            }}</label>
          </div>
          <div class="col-8">
            <nb-select
              fullWidth
              id="vitroType"
              appInputValidationStatus
              class="radio-toolbar"
              formControlName="vitroType"
              [placeholder]="
                'serviceData.form.placeholders.vitroType' | translate
              "
              data-cy="vitro-type-select"
            >
              @for (type of Object.keys(InVitroTypes); track type) {
                <nb-option [value]="type">{{
                  'serviceData.form.inVitro.' + type | translate
                }}</nb-option>
              }
            </nb-select>
          </div>
        </div>
        <div class="row justify-content-end">
          <div class="col-8">
            <app-validation-error
              for="vitroType"
              translations="validation.generic"
            ></app-validation-error>
          </div>
        </div>
      }
    </div>
  </div>
  @if (form.get('inVitro.isInVitroFertilized').value) {
    <div class="row form-group">
      <div class="col-6">
        <div class="row align-items-center">
          <div class="col-4">
            <label for="isSurrogateMother">{{
              'serviceData.form.isSurrogateMother' | translate
            }}</label>
          </div>
          <div class="col-8">
            <div class="col-9">
              <nb-radio-group
                id="isSurrogateMother"
                name="isSurrogateMother"
                class="radio-toolbar"
                formControlName="isSurrogateMother"
                data-cy="surrogate-mother-rg"
              >
                <nb-radio [value]="true">{{
                  'common.yes' | translate
                }}</nb-radio>
                <nb-radio [value]="false">{{
                  'common.no' | translate
                }}</nb-radio>
              </nb-radio-group>
            </div>
          </div>
        </div>
        <div class="row justify-content-end">
          <div class="col-8">
            <app-validation-error
              for="isSurrogateMother"
              translations="validation.generic"
            ></app-validation-error>
          </div>
        </div>
      </div>
    </div>
  }
</ng-container>

<div class="row form-group" formGroupName="vanishingTwinSyndrome">
  <div class="col-6">
    <div class="row align-items-center">
      <div class="col-4">
        <label for="vanishingTwinSyndromeIsDetected">{{
          'serviceData.form.vanishingTwinSyndrome' | translate
        }}</label>
      </div>
      <div class="col-8">
        <div class="col-9">
          <nb-radio-group
            id="vanishingTwinSyndromeIsDetected"
            name="vanishingTwinSyndromeIsDetected"
            class="radio-toolbar"
            formControlName="isDetected"
            data-cy="vanishing-twin-syndrome-rg"
          >
            <nb-radio [value]="true">{{ 'common.yes' | translate }}</nb-radio>
            <nb-radio [value]="false">{{ 'common.no' | translate }}</nb-radio>
          </nb-radio-group>
        </div>
      </div>
    </div>
    <div class="row justify-content-end">
      <div class="col-8">
        <app-validation-error
          for="isDetected"
          translations="validation.generic"
        ></app-validation-error>
      </div>
    </div>
  </div>
  <div class="col-6">
    @if (form.get('vanishingTwinSyndrome.isDetected').value) {
      <div class="row align-items-center" formGroupName="detectedGestationAge">
        <div class="col-4">
          <label>
            {{ 'serviceData.form.vtsGestationAge' | translate }}
          </label>
        </div>
        <div class="col-8">
          <div class="row">
            <div class="col-6">
              <input
                fullWidth
                nbInput
                type="number"
                formControlName="week"
                [placeholder]="
                  'serviceData.form.placeholders.gestationAgeWeek' | translate
                "
                data-cy="vts-gestation-week-input"
              />
            </div>
            <div class="col-6">
              <input
                fullWidth
                nbInput
                type="number"
                formControlName="day"
                [placeholder]="
                  'serviceData.form.placeholders.gestationAgeDay' | translate
                "
                data-cy="vts-gestation-day-input"
              />
            </div>
          </div>
        </div>
        <div class="row justify-content-end m-0 p-0">
          <div class="col-4">
            <app-validation-error
              for="week"
              translations="validation.generic"
            ></app-validation-error>
          </div>
          <div class="col-4">
            <app-validation-error
              for="day"
              translations="validation.generic"
            ></app-validation-error>
          </div>
        </div>
      </div>
    }
  </div>
</div>

<div class="row form-group" formGroupName="heparinTherapy">
  <div class="col-6">
    <div class="row align-items-center">
      <div class="col-4">
        <label for="heparinTherapy">{{
          'serviceData.form.heparinTherapy' | translate
        }}</label>
      </div>
      <div class="col-8">
        <div class="col-9">
          <nb-radio-group
            id="heparinTherapy"
            name="heparinTherapy"
            class="radio-toolbar"
            formControlName="hasReceived"
            data-cy="heparin-therapy-rg"
          >
            <nb-radio [value]="true">{{ 'common.yes' | translate }}</nb-radio>
            <nb-radio [value]="false">{{ 'common.no' | translate }}</nb-radio>
          </nb-radio-group>
        </div>
      </div>
    </div>
    <div class="row justify-content-end">
      <div class="col-8">
        <app-validation-error
          for="hasReceived"
          translations="validation.generic"
        ></app-validation-error>
      </div>
    </div>
  </div>
  <div class="col-6">
    @if (form.get('heparinTherapy.hasReceived').value) {
      <div class="row align-items-center">
        <div class="col-4">
          <label>{{
            'serviceData.form.heparinTherapyIsRelevant' | translate:{hours: !hasMonoProduct?24:48 }
          }}</label>
        </div>
        <div class="col-8">
          <nb-radio-group
            id="heparinTherapyIsRelevant"
            name="heparinTherapyIsRelevant"
            class="radio-toolbar"
            formControlName="isRelevant"
            data-cy="heparin-therapy-relevant-rg"
          >
            <nb-radio [value]="false">{{ 'common.yes' | translate }}</nb-radio>
            <nb-radio [value]="true">{{ 'common.no' | translate }}</nb-radio>
          </nb-radio-group>
        </div>
      </div>
      <div class="row justify-content-end">
        <div class="col-8">
          <app-validation-error
            for="isRelevant"
            translations="serviceData.form.validation.heparinTherapy"
          ></app-validation-error>
        </div>
      </div>
    }
  </div>
</div>

<div class="row form-group">
  <div class="col-6">
    <div class="row align-items-center">
      <div class="col-4">
        <label for="ultrasoundDate">{{
          'serviceData.form.ultrasoundDate' | translate
        }}</label>
      </div>
      <div class="col-8">
        <input
          fullWidth
          nbInput
          formControlName="ultrasoundDate"
          id="ultrasoundDate"
          appInputValidationStatus
          [nbDatepicker]="ultrasoundDatepicker"
          [placeholder]="'serviceData.form.placeholders.dateFormat' | translate"
          data-cy="ultrasound-date-input"
        />
        <nb-datepicker
          [min]="minDate"
          [max]="maxDate"
          [format]="DATEPICKER_DATE_FORMAT"
          #ultrasoundDatepicker
        ></nb-datepicker>
      </div>
    </div>
    <div class="row justify-content-end">
      <div class="col-8">
        <app-validation-error
          for="ultrasoundDate"
          translations="validation.generic"
        ></app-validation-error>
      </div>
    </div>
  </div>
</div>

<ng-container formGroupName="priorScreeningTest">
  <div class="row form-group">
    <div class="col-6">
      <div class="row align-items-center">
        <div class="col-4">
          <label for="priorScreeningTest">{{
            'serviceData.form.priorScreeningTest' | translate
          }}</label>
        </div>
        <div class="col-8">
          <nb-select
            class="w-100"
            id="priorScreeningTest"
            appInputValidationStatus
            formControlName="priorScreeningTestType"
            [placeholder]="
              'serviceData.form.placeholders.priorScreeningTest' | translate
            "
            data-cy="prior-screening-test-select"
          >
            @for (type of Object.keys(PriorScreeningTestType); track type) {
              <nb-option [value]="type">{{
                'serviceData.form.priorScreeningTestType.' + type | translate
              }}</nb-option>
            }
          </nb-select>
        </div>
      </div>
      <div class="row justify-content-end">
        <div class="col-8">
          <app-validation-error
            for="priorScreeningTestType"
            translations="validation.generic"
          ></app-validation-error>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-6" formGroupName="firstTRisks">
      <div class="row align-items-center">
        <div class="col-4">
          <label for="firstT21Risk">{{
            'serviceData.form.firstT21Risk' | translate
          }}</label>
        </div>
        <div class="col-8">
          <input
            fullWidth
            nbInput
            type="number"
            formControlName="t21Risk"
            id="firstT21Risk"
            appInputValidationStatus
            [placeholder]="
              'serviceData.form.placeholders.firstT21Risk' | translate
            "
            data-cy="first-T21-risk-input"
          />
        </div>
      </div>
      <div class="row form-group justify-content-end">
        <div class="col-8">
          <app-validation-error
            for="t21Risk"
            translations="serviceData.form.validation.trisomy"
          ></app-validation-error>
        </div>
      </div>

      <div class="row align-items-center">
        <div class="col-4">
          <label for="firstT18Risk">
            {{ 'serviceData.form.firstT18Risk' | translate }}
          </label>
        </div>
        <div class="col-8">
          <input
            fullWidth
            nbInput
            type="number"
            formControlName="t18Risk"
            id="firstT18Risk"
            appInputValidationStatus
            [placeholder]="
              'serviceData.form.placeholders.firstT18Risk' | translate
            "
            data-cy="first-T18-risk-input"
          />
        </div>
      </div>
      <div class="row form-group justify-content-end">
        <div class="col-8">
          <app-validation-error
            for="t18Risk"
            translations="serviceData.form.validation.trisomy"
          ></app-validation-error>
        </div>
      </div>
      <div class="row align-items-center">
        <div class="col-4">
          <label for="firstT13Risk">{{
            'serviceData.form.firstT13Risk' | translate
          }}</label>
        </div>
        <div class="col-8">
          <input
            fullWidth
            nbInput
            type="number"
            formControlName="t13Risk"
            id="firstT13Risk"
            appInputValidationStatus
            [placeholder]="
              'serviceData.form.placeholders.firstT13Risk' | translate
            "
            data-cy="first-T13-risk-input"
          />
        </div>
      </div>
      <div class="row form-group justify-content-end">
        <div class="col-8">
          <app-validation-error
            for="t13Risk"
            translations="serviceData.form.validation.trisomy"
          ></app-validation-error>
        </div>
      </div>
    </div>
    <div class="col-6" formGroupName="secondTRisks">
      <div class="row align-items-center">
        <div class="col-4">
          <label for="secondT21Risk">{{
            'serviceData.form.secondT21Risk' | translate
          }}</label>
        </div>
        <div class="col-8">
          <input
            fullWidth
            nbInput
            type="number"
            formControlName="t21Risk"
            id="secondT21Risk"
            appInputValidationStatus
            [placeholder]="
              'serviceData.form.placeholders.secondT21Risk' | translate
            "
            data-cy="second-T21-risk-input"
          />
        </div>
      </div>
      <div class="row form-group justify-content-end">
        <div class="col-8">
          <app-validation-error
            for="t21Risk"
            translations="serviceData.form.validation.trisomy"
          ></app-validation-error>
        </div>
      </div>
      <div class="row align-items-center">
        <div class="col-4">
          <label for="secondT18Risk">{{
            'serviceData.form.secondT18Risk' | translate
          }}</label>
        </div>
        <div class="col-8">
          <input
            fullWidth
            nbInput
            type="number"
            formControlName="t18Risk"
            id="secondT18Risk"
            appInputValidationStatus
            [placeholder]="
              'serviceData.form.placeholders.secondT18Risk' | translate
            "
            data-cy="second-T18-risk-input"
          />
        </div>
      </div>
      <div class="row form-group justify-content-end">
        <div class="col-8">
          <app-validation-error
            for="t18Risk"
            translations="serviceData.form.validation.trisomy"
          ></app-validation-error>
        </div>
      </div>
      <div class="row align-items-center">
        <div class="col-4">
          <label for="secondT13Risk">{{
            'serviceData.form.secondT13Risk' | translate
          }}</label>
        </div>
        <div class="col-8">
          <input
            fullWidth
            nbInput
            type="number"
            formControlName="t13Risk"
            id="secondT13Risk"
            appInputValidationStatus
            [placeholder]="
              'serviceData.form.placeholders.secondT13Risk' | translate
            "
            data-cy="second-T13-risk-input"
          />
        </div>
      </div>
      <div class="row form-group justify-content-end">
        <div class="col-8">
          <app-validation-error
            for="t13Risk"
            translations="serviceData.form.validation.trisomy"
          ></app-validation-error>
        </div>
      </div>
    </div>
  </div>
</ng-container>
