import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { permissions } from '@app/modules/auth/auth.contants';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from '@root/environments/environment';
import { WebAuth } from 'auth0-js';
import { get } from 'lodash';
import { from, Observable, of, ReplaySubject } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { AUTH0_CONFIG, AUTH_TOKEN_ROLES_KEY } from '../../../app.constants';
import { ErrorMessage, LoginUserData, UserRoles } from '../core.types';
import { CoreUtilsService } from '../utils/core-utils.service';
import { LocalStorageService } from './local-storage.service';
import { Logger } from './logger.service';

@Injectable()
export class AuthenticationService {
  private readonly accessTokenSubject = new ReplaySubject<string>(1);
  private readonly log = new Logger('AuthenticationService');
  private jwtHelper = new JwtHelperService();
  private apiUrl: string;

  constructor(
    private localStorageService: LocalStorageService,
    private coreUtils: CoreUtilsService,
    private httpClient: HttpClient
  ) {
    this.apiUrl = environment.apiUrl;
  }

  async initialize() {
    this.log.debug('init');
    const storedCredentials =
      this.localStorageService.getCredentialsFromLocalStorage();
    if (
      storedCredentials &&
      storedCredentials.accessToken &&
      !this.jwtHelper.isTokenExpired(storedCredentials.accessToken)
    ) {
      return this.accessTokenSubject.next(storedCredentials.accessToken);
    }
    this.accessTokenSubject.next(await this.getRefreshedAccessToken());
  }

  getAccessToken$(): Observable<string> {
    return this.accessTokenSubject.pipe(
      switchMap((token) => {
        try {
          if (token && !this.jwtHelper.isTokenExpired(token)) {
            return of(token);
          }
          // eslint-disable-next-line no-empty
        } catch (err) {}
        return from(this.getRefreshedAccessToken());
      })
    );
  }

  login(loginUserData: LoginUserData): Promise<ErrorMessage> {
    return new Promise<ErrorMessage>((resolve, reject) => {
      const auth0 = this.getAuth0Client();
      auth0.client.login(
        {
          username: loginUserData.username,
          password: loginUserData.password,
          realm: AUTH0_CONFIG.usernamePasswordRealm,
          scope: AUTH0_CONFIG.scope,
        },
        (err, authResult) => {
          if (!err && authResult && authResult.accessToken) {
            this.localStorageService.saveCredentialsToLocalStorage(authResult);
            this.accessTokenSubject.next(authResult.accessToken);
            resolve(null);
          } else {
            reject(err);
            return;
          }
        }
      );
    });
  }

  logOut() {
    this.localStorageService.clearLocalStorage();
    this.accessTokenSubject.next(null);
  }

  getRefreshedAccessToken(): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const refreshToken = this.localStorageService.getRefreshToken();

      if (refreshToken) {
        const options = {
          grantType: AUTH0_CONFIG.refreshTokenGrantType,
          clientID: environment.auth0.clientId,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          refresh_token: refreshToken,
        };
        const auth0 = this.getAuth0Client();
        auth0.client.oauthToken(options, (error, result) => {
          if (error) {
            // reject(error.description);
            resolve(null);
            return;
          } else {
            this.localStorageService.saveAccessTokenToLocalStorage(
              result.accessToken
            );
            resolve(result.accessToken);
          }
        });
      } else {
        resolve(null);
      }
    });
  }

  hasRequiredRole$(
    requiredRoles: (UserRoles | UserRoles[])[]
  ): Observable<boolean> {
    return this.getUserRoles$().pipe(
      map((userRoles) => {
        if (userRoles && requiredRoles) {
          return requiredRoles.some((role) =>
            Array.isArray(role)
              ? this.hasRoles(role, userRoles)
              : this.hasRole(role, userRoles)
          );
        }
        return false;
      })
    );
  }

  public hasRequiredPermission(requiredPermission: string | string[]): boolean {
    const roles = this.getUserRoles();
    const hasPermission = this.hasRolePermission(requiredPermission, roles);

    this.logPermission(requiredPermission, hasPermission);

    return hasPermission;
  }

  hasRequiredPermission$(
    requiredPermission: string | string[]
  ): Observable<boolean> {
    return this.getUserRoles$().pipe(
      map((roles) => {
        return this.hasRolePermission(requiredPermission, roles);
      }),
      tap((hasPermission) =>
        this.logPermission(requiredPermission, hasPermission)
      )
    );
  }

  hasRole(requiredRole: UserRoles, userRoles: UserRoles[]) {
    return userRoles.indexOf(requiredRole) !== -1;
  }

  hasRoles(requiredRoles: UserRoles[], userRoles: UserRoles[]): boolean {
    return requiredRoles.every((role) => this.hasRole(role, userRoles));
  }

  getUserRoles$(): Observable<UserRoles[]> {
    return this.getAccessToken$().pipe(
      map((token) => {
        if (token) {
          return this.getRolesFromAccessToken(token);
        }
        return [];
      })
    );
  }

  getRolesFromAccessToken(accessToken: string): UserRoles[] {
    try {
      const decodedToken = this.jwtHelper.decodeToken(accessToken);
      return decodedToken[AUTH_TOKEN_ROLES_KEY];
    } catch (error) {
      return null;
    }
  }

  forgotPassword(username: string): Observable<any> {
    this.log.debug('request password reset');
    const payload = this.coreUtils.getRequestPasswordResetPayload(
      environment.auth0.domain,
      username
    );
    return this.httpClient.post(
      `${this.apiUrl}/userAccounts/me/requestPasswordReset`,
      payload
    );
  }

  getUserId() {
    const { userId } =
      this.localStorageService.getCredentialsFromLocalStorage();
    return userId;
  }

  private getAuth0Client() {
    return new WebAuth({
      domain: environment.auth0.domain,
      clientID: environment.auth0.clientId,
      audience: environment.auth0.audience,
      responseType: AUTH0_CONFIG.responseType,
    });
  }

  private hasRolePermission(
    requiredPermission: string | string[],
    roles: string[]
  ): boolean {
    const requiredPermissions = Array.isArray(requiredPermission)
      ? requiredPermission
      : [requiredPermission];

    // Some elements require multiple permissions, loop through and return false if any of them missing.
    // Permissions might exist on separate roles - ex. local + invoice-exporter
    for (const permission of requiredPermissions) {
      let found = false;
      for (const role of roles) {
        if (get(permissions?.[role], permission, false)) {
          found = true;
        }
      }
      if (!found) {
        return false;
      }
    }
    return true;
  }

  private logPermission(
    requiredPermission: string | string[],
    hasPermission: boolean
  ): void {
    console.log(
      `%c[Permission] ${requiredPermission}`,
      'font-weight: bold; color: #228B22;',
      ` - ${hasPermission}`
    );
  }

  private getAccessToken() {
    const storedCredentials =
      this.localStorageService.getCredentialsFromLocalStorage();
    if (
      storedCredentials &&
      storedCredentials.accessToken &&
      !this.jwtHelper.isTokenExpired(storedCredentials.accessToken)
    ) {
      return storedCredentials.accessToken;
    }
  }

  private getUserRoles(): UserRoles[] {
    const token = this.getAccessToken();
    return token ? this.getRolesFromAccessToken(token) : [];
  }
}
