import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DEFAULT_DIALOG_OPTIONS } from '@app/app.constants';
import { LanguagesService } from '@core/services/languages.service';
import { UserProfileService } from '@core/services/user-profile.service';
import {
  NbButtonModule,
  NbCardModule,
  NbCheckboxModule,
  NbDatepickerModule,
  NbDialogModule,
  NbIconModule,
  NbInputModule,
  NbListModule,
  NbProgressBarModule,
  NbRadioModule,
  NbSelectModule,
  NbSpinnerModule,
  NbTagModule,
  NbTimepickerModule,
  NbTooltipModule,
  NbTreeGridModule,
} from '@nebular/theme';
import {
  NgbDropdownModule,
  NgbPaginationModule,
  NgbTypeaheadModule,
} from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import {
  LoadingDialogComponent,
  LoadingService,
  LoadingState,
} from '@shared/components/loading-dialog/loading-dialog.component';
import { PdfIframePreviewComponent } from '@shared/components/pdf-iframe-preview/pdf-iframe-preview.component';
import { NgLetDirective } from '@shared/directives/ngLet';
import { ApproveDocumentDialogComponent } from '../treatments/components/approve-document-dialog/approve-document-dialog.component';
import { UploadDocumentDialogComponent } from '../treatments/components/upload-document-dialog/upload-document-dialog.component';
import {
  ConfirmDialogComponent,
  ConfirmService,
  ConfirmState,
} from './components/confirm/confirm-dialog.component';
import { DateTimeComponent } from './components/date-time/date-time.component';
import { PartnerDisplayComponent } from './components/partner-display/partner-display.component';
import { PartnerTypeaheadComponent } from './components/partner-typeahead/partner-typeahead.component';
import { PdfIframeInlineComponent } from './components/pdf-iframe-inline/pdf-iframe-inline.component';
import { ProductDisplayComponent } from './components/product-display/product-display.component';
import { RatingComponent } from './components/rating/rating.component';
import { SelectionListComponent } from './components/selection-list/selection-list.component';
import { UserDisplayComponent } from './components/user-display/user-display.component';
import {
  WarningDialogComponent,
  WarningService,
} from './components/warning/warning-dialog.component';
import { RequireRoleDirective } from './directives/requireRole.directive';
import { OrderBy } from './pipes/order-by.pipe';
import { DhlWaybillDirective } from '@shared/directives/dhl-waybill.directive';
import { ProgressDialogComponent } from '@shared/components/progress/progress-dialog.component';
import { PasswordDialogComponent } from './components/password-dialog/password-dialog.component';
import { CtrlSDirective } from './directives/ctrl-s.directive';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { DisplayNamePipe } from './pipes/display-name.pipe';
import { ShortenFilenamePipe } from './pipes/shorten-filename.pipe';
import { GetObjectPipe } from './pipes/get-object.pipe';
import { NotificationProfileRecipientsPipe } from './pipes/notification-profile-recipients.pipe';
import { DataTableComponent } from './components/data-table/data-table.component';
import { MatTableModule } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { DataTableNestableActionsComponent } from './components/data-table/data-table-nestable-actions/data-table-nestable-actions.component';
import { RequirePermissionDirective } from './directives/require-permission.directive';
import { TreatmentDetailsDhlWaybillComponent } from '@treatments/components/treatment-details-dhl-waybill/treatment-details-dhl-waybill.component';
import { DocumentInteractionsListComponent } from '@shared/components/document-interactions-list/document-interactions-list.component';

@NgModule({
  imports: [
    CommonModule,
    NbCardModule,
    NbTooltipModule,
    NbIconModule,
    NbListModule,
    NbTreeGridModule,
    TranslateModule,
    NbButtonModule,
    NbDialogModule.forChild(DEFAULT_DIALOG_OPTIONS),
    NbInputModule,
    ReactiveFormsModule,
    NbDatepickerModule,
    NbTimepickerModule.forRoot(),
    NgbTypeaheadModule,
    NgbPaginationModule,
    NbSpinnerModule,
    NbSelectModule,
    NbCheckboxModule,
    NbRadioModule,
    FormsModule,
    NgbDropdownModule,
    NbTagModule,
    NbProgressBarModule,
    InfiniteScrollModule,
    MatTableModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
  ],
  providers: [
    LanguagesService,
    UserProfileService,
    ConfirmState,
    ConfirmService,
    LoadingState,
    LoadingService,
    WarningService,
  ],
  declarations: [
    OrderBy,
    SelectionListComponent,
    ConfirmDialogComponent,
    DateTimeComponent,
    LoadingDialogComponent,
    NgLetDirective,
    RequireRoleDirective,
    PdfIframePreviewComponent,
    PartnerTypeaheadComponent,
    RatingComponent,
    WarningDialogComponent,
    TreatmentDetailsDhlWaybillComponent,
    UploadDocumentDialogComponent,
    PdfIframeInlineComponent,
    ApproveDocumentDialogComponent,
    UserDisplayComponent,
    PartnerDisplayComponent,
    ProductDisplayComponent,
    DhlWaybillDirective,
    ProgressDialogComponent,
    PasswordDialogComponent,
    CtrlSDirective,
    DisplayNamePipe,
    ShortenFilenamePipe,
    GetObjectPipe,
    NotificationProfileRecipientsPipe,
    DataTableComponent,
    DataTableNestableActionsComponent,
    RequirePermissionDirective,
    DocumentInteractionsListComponent,
  ],
  exports: [
    SelectionListComponent,
    TranslateModule,
    ConfirmDialogComponent,
    DateTimeComponent,
    LoadingDialogComponent,
    NgLetDirective,
    RequireRoleDirective,
    PdfIframePreviewComponent,
    PartnerTypeaheadComponent,
    RatingComponent,
    WarningDialogComponent,
    PdfIframeInlineComponent,
    UserDisplayComponent,
    PartnerDisplayComponent,
    ProductDisplayComponent,
    DhlWaybillDirective,
    CtrlSDirective,
    DisplayNamePipe,
    ShortenFilenamePipe,
    GetObjectPipe,
    NotificationProfileRecipientsPipe,
    DataTableComponent,
    RequirePermissionDirective,
    DocumentInteractionsListComponent,
  ],
})
export class SharedModule {}
