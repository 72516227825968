import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  API_SPECIFICATION_CONSTANTS,
  NIFTY_APPLICATIONID_KEY,
  PARTNERS_API_CONSTANTS,
  PROPERTY_KEYS,
} from '@app/app.constants';
import { environment } from '@root/environments/environment';
import { Observable } from 'rxjs';
import { share } from 'rxjs/operators';
import { Partner } from '../core.types';
import { EntityBaseService } from './entityBase.service';

@Injectable({
  providedIn: 'root',
})
export class PartnersService extends EntityBaseService<Partner> {
  constructor(protected http: HttpClient) {
    super(http);
    this.apiUrl = `${environment.apiUrl}/${PARTNERS_API_CONSTANTS.partners}`;
  }

  createDoctor(doctor: Partner): Observable<any> {
    const url = `${this.apiUrl}/${PARTNERS_API_CONSTANTS.actorPartnerManager}/!/${API_SPECIFICATION_CONSTANTS.tell}/${PARTNERS_API_CONSTANTS.commandProvisionPartner}`;
    return this.http.post(url, doctor);
  }

  updateDoctor(doctor: Partner): Observable<any> {
    const url = `${this.apiUrl}/${PARTNERS_API_CONSTANTS.actorPartnerManager}/!/${API_SPECIFICATION_CONSTANTS.tell}/${PARTNERS_API_CONSTANTS.commandProvisionPartnerUpdate}`;
    return this.http.post(url, doctor);
  }

  assignDoctorToPartner(partnerId: string, doctorId: string): Observable<any> {
    const url = `${this.apiUrl}/${PARTNERS_API_CONSTANTS.actorPartner}/${partnerId}/${API_SPECIFICATION_CONSTANTS.tell}/${PARTNERS_API_CONSTANTS.commandAssignSubPartnerToPartner}`;
    return this.http.post(url, {
      partnerId: doctorId,
    });
  }

  unassignDoctorFromPartner(
    partnerId: string,
    doctorId: string
  ): Observable<any> {
    const url = `${this.apiUrl}/${PARTNERS_API_CONSTANTS.actorPartner}/${partnerId}/${API_SPECIFICATION_CONSTANTS.tell}/${PARTNERS_API_CONSTANTS.commandUnassignSubPartnerFromPartner}`;
    return this.http.post(url, {
      partnerId: doctorId,
    });
  }

  deactivatePartner(partnerId: string) {
    const url = `${this.apiUrl}/${PARTNERS_API_CONSTANTS.actorPartner}/${partnerId}/${API_SPECIFICATION_CONSTANTS.tell}/${PARTNERS_API_CONSTANTS.commandDeactivatePartner}`;
    return this.http.post(url, {});
  }

  deactivateDoctor(doctorId: string): Observable<any> {
    return this.deactivatePartner(doctorId);
  }

  activatePartner(partnerId: string): Observable<any> {
    const url = `${this.apiUrl}/${PARTNERS_API_CONSTANTS.actorPartner}/${partnerId}/${API_SPECIFICATION_CONSTANTS.tell}/${PARTNERS_API_CONSTANTS.commandReactivatePartner}`;
    return this.http.post(url, {});
  }

  setProductConfiguratonToPartner(
    partnerId: string,
    productConfigurationId: string,
    isAssign: boolean
  ) {
    this.logger.debug('setProductConfiguratonToPartner');

    let productConfiguratonUrl = `${this.apiUrl}/${PARTNERS_API_CONSTANTS.actorPartner}/${partnerId}/${API_SPECIFICATION_CONSTANTS.tell}`;

    if (isAssign) {
      productConfiguratonUrl = productConfiguratonUrl.concat(
        `/${PARTNERS_API_CONSTANTS.commandAssignProductConfigurationToPartner}`
      );
    } else {
      productConfiguratonUrl = productConfiguratonUrl.concat(
        `/${PARTNERS_API_CONSTANTS.commandUnassignProductConfigurationFromPartner}`
      );
    }

    const body = {
      productConfigurationId,
    };

    return this.http.post<string>(productConfiguratonUrl, body);
  }

  assignBundleToPartner(
    partnerId: string,
    bundleId: string
  ): Observable<{ id: string }> {
    this.logger.debug('assignBundleToPartner');

    return this.http.post<{ id: string }>(
      `${environment.apiUrl}/partners/${partnerId}/bundle-configuration`,
      {
        bundleId,
      }
    );
  }

  unassignBundleConfigurationFromPartner(
    partnerId: string,
    bundleConfigurationId: string
  ) {
    this.logger.debug('unassignBundleConfigurationFromPartner');

    return this.http.delete<void>(
      `${environment.apiUrl}/partners/${partnerId}/bundle-configuration/${bundleConfigurationId}`
    );
  }

  assignUserAccountToPartner(partnerId: string, userAccountId: string) {
    const assignUserAccountUrl = `${this.apiUrl}/${PARTNERS_API_CONSTANTS.actorPartner}/${partnerId}/${API_SPECIFICATION_CONSTANTS.tell}/${PARTNERS_API_CONSTANTS.commandAssignUserAccountToPartner}`;

    return this.http.post(assignUserAccountUrl, {
      id: userAccountId,
      provider: 'Auth0',
    });
  }

  assignNotificationProfileToPartner(
    partnerId: string,
    notificationProfileId: string
  ) {
    const assignNotificationProfileUrl =
      `${this.apiUrl}/${PARTNERS_API_CONSTANTS.actorPartner}/${partnerId}/` +
      `${API_SPECIFICATION_CONSTANTS.tell}/${PARTNERS_API_CONSTANTS.commandAssignNotificationProfileToPartner}`;

    return this.http.post(assignNotificationProfileUrl, {
      notificationProfileId,
    });
  }

  unassignNotificationProfileFromPartner(
    partnerId: string,
    notificationProfileId: string
  ) {
    const unassignNotificationProfileUrl =
      `${this.apiUrl}/${PARTNERS_API_CONSTANTS.actorPartner}/${partnerId}/` +
      `${API_SPECIFICATION_CONSTANTS.tell}/${PARTNERS_API_CONSTANTS.commandUnassignNotificationProfileFromPartner}`;

    return this.http.post(unassignNotificationProfileUrl, {
      notificationProfileId,
    });
  }

  findPartners(
    partnerTypesIds?: string[],
    searchString?: string,
    roleCountries?: string[]
  ): Observable<Partner[]> {
    let params = new HttpParams();
    if (partnerTypesIds && partnerTypesIds.length) {
      params = params.set('partnerTypeIds', partnerTypesIds.join(','));
    }
    if (searchString) {
      params = params.set(
        API_SPECIFICATION_CONSTANTS.searchToken,
        searchString
      );
    }
    if (roleCountries) {
      params = params.set('AddressCountryTokens', roleCountries.join(','));
    }
    return this.http.get<Partner[]>(this.getListUrl(), {
      params,
    });
  }

  getPartnersByNametoken(partnerNameToken: string) {
    return this.http.get<Partner[]>(this.getListUrl(), {
      params: new HttpParams().set('NameToken', partnerNameToken),
    });
  }

  getPartners(partnerIds: string[]): Observable<Partner[]> {
    const getPartnersUrl =
      `${this.apiUrl}/${PARTNERS_API_CONSTANTS.actorPartnerDirectory}/!/${API_SPECIFICATION_CONSTANTS.ask}` +
      `/${PARTNERS_API_CONSTANTS.commandListPartners}`;
    return this.http
      .get<Partner[]>(getPartnersUrl, {
        params: new HttpParams().set('Ids', partnerIds.join(',')),
      })
      .pipe(share());
  }

  getPartner(partnerId: string): Observable<Partner> {
    const getPartnersUrl =
      `${this.apiUrl}/${PARTNERS_API_CONSTANTS.actorPartnerProjector}/${partnerId}/${API_SPECIFICATION_CONSTANTS.ask}` +
      `/${PARTNERS_API_CONSTANTS.commandGetPartner}`;

    return this.http.get<Partner>(getPartnersUrl);
  }

  assignPaymentTypeToPartner(
    partnerId: string,
    paymentType: string,
    assign: boolean
  ) {
    this.logger.debug('assign payment type to partner');
    let url = `${this.apiUrl}/${PARTNERS_API_CONSTANTS.actorPartner}/${partnerId}/${API_SPECIFICATION_CONSTANTS.tell}/`;
    if (assign) {
      url = `${url}${PARTNERS_API_CONSTANTS.commandAssignPaymentTypeToPartner}`;
    } else {
      url = `${url}${PARTNERS_API_CONSTANTS.commandUnassignPaymentTypeFromPartner}`;
    }

    return this.http.post(url, { paymentType });
  }

  getOnlinePartners(countries?: string[]): Observable<Partner[]> {
    let params = new HttpParams()
      .set('PropertyValue', 'True')
      .set('PropertyKey', PROPERTY_KEYS.IsOnlinePartner);

    if (countries) {
      params = params.set('AddressCountryTokens', countries?.join(','));
    }

    return this.http.get<Partner[]>(this.getListUrl(), {
      params,
    });
  }

  protected setUpdateEntityBody(entity: Partner, body: Partial<Partner>) {
    return body;
  }
  protected setUrlBody(partner: Partner) {
    return partner;
  }
  protected getListUrl(): string {
    return (
      `${this.apiUrl}/${PARTNERS_API_CONSTANTS.actorPartnerDirectory}/!/${API_SPECIFICATION_CONSTANTS.ask}/` +
      `${PARTNERS_API_CONSTANTS.commandListPartners}?${API_SPECIFICATION_CONSTANTS.applicationId}=${NIFTY_APPLICATIONID_KEY}`
    );
  }
  protected getUpdateEntityUrl(entityId: string): string {
    return `${this.apiUrl}/${PARTNERS_API_CONSTANTS.actorPartnerManager}/!/${API_SPECIFICATION_CONSTANTS.tell}/${PARTNERS_API_CONSTANTS.commandProvisionPartnerUpdate}`;
  }
  protected getDeleteEntityUrl(entityId: string): string {
    throw new Error('Method not implemented.');
  }
  protected getCreateEntityUrl(newEntityId: string): string {
    return `${this.apiUrl}/${PARTNERS_API_CONSTANTS.actorPartnerManager}/!/${API_SPECIFICATION_CONSTANTS.tell}/${PARTNERS_API_CONSTANTS.commandProvisionPartner}`;
  }
  protected getFetchEntityUrl(entityId: string): string {
    throw new Error('Method not implemented.');
  }
  protected setCreateEntityBody(entity: Partner, body: Partial<Partner>) {
    return body;
  }
}
