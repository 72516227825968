import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EXPORTERS_API_CONSTANTS, PAGING_HEADERS } from '@app/app.constants';
import { environment } from '@root/environments/environment';
import { Observable } from 'rxjs';
import { KitPositionModel, Run, SortDirection } from '../core.types';
import { StatusResponse } from '@treatments/treatment.types';
import { map } from 'rxjs/internal/operators/map';
import { RunsUtilsService } from '@app/modules/runs/runs-utils.service';

@Injectable({
  providedIn: 'root',
})
export class RunsService {
  private exportersApiUrl: string;
  private commandPlasmaExport: string;
  private commandBgiPlasmaExport: string;
  private commandHalosExport: string;
  private readonly apiUrl = `${environment.apiUrl}/nipts/runs`;

  constructor(
    protected http: HttpClient,
    private runsUtils: RunsUtilsService
  ) {
    this.exportersApiUrl = `${environment.exportersApiUrl}/v2`;
    this.commandPlasmaExport = EXPORTERS_API_CONSTANTS.commandPlasmaExport;
    this.commandBgiPlasmaExport =
      EXPORTERS_API_CONSTANTS.commandBgiPlasmaExport;
    this.commandHalosExport = EXPORTERS_API_CONSTANTS.commandHalosExport;
  }

  getRun(runId: string) {
    return this.http.get(`${this.apiUrl}/${runId}`);
  }

  generateRun(run: Run) {
    return this.http.post<{ id: string }>(this.apiUrl, run);
  }

  updateRun(run: Run) {
    return this.http.put(`${this.apiUrl}/${run.id}`, run);
  }

  deleteRun(runId: string) {
    return this.http.delete(`${this.apiUrl}/${runId}`);
  }

  getRuns(
    queryParams: RunFilters,
    headers: RunHeaders
  ): Observable<{
    items: Run[];
    continuationToken: string;
    totalCount: number;
  }> {
    return this.http
      .get<Run[]>(this.apiUrl, {
        params: this.runsUtils.buildHttpParams(queryParams),
        headers: this.runsUtils.buildHttpHeaders(headers),
        observe: 'response',
      })
      .pipe(
        map((response) => ({
          items: response.body,
          continuationToken: response.headers.get(
            PAGING_HEADERS.continuationToken
          ),
          totalCount: parseInt(
            response.headers.get(PAGING_HEADERS.totalCount),
            10
          ),
        }))
      );
  }

  unassignKitFromRun(runId: string, kitId: string) {
    return this.http.patch(`${this.apiUrl}/${runId}/kit/${kitId}/unassign`, {
      kitId,
    });
  }

  assignKitToRun(runId: string, kitPositionItem: KitPositionModel) {
    return this.http.patch(
      `${this.apiUrl}/${runId}/kit/${kitPositionItem.kitId}`,
      kitPositionItem
    );
  }

  addKitToRun(runId: string, kitId: string) {
    return this.http.patch(`${this.apiUrl}/${runId}/kit/${kitId}`, {
      runId,
      kitId,
    });
  }

  removeKitFromRun(runId: string, kitId: string) {
    return this.http.patch(`${this.apiUrl}/${runId}/kit/${kitId}/remove`, {
      kitId,
    });
  }

  markRunAsStarted(runId: string, started: Date) {
    return this.http.patch(`${this.apiUrl}/${runId}/status/start`, { started });
  }

  markRunAsFinished(runId: string, finished: Date) {
    return this.http.patch(`${this.apiUrl}/${runId}/status/finish`, {
      finished,
    });
  }

  plasmaExport(
    id: string,
    uri: string,
    runId: string
  ): Observable<StatusResponse> {
    return this.http.post<StatusResponse>(
      `${this.exportersApiUrl}/${this.commandPlasmaExport}`,
      { blobUri: uri, runId }
    );
  }

  bgiPlasmaExport(
    id: string,
    uri: string,
    runId: string,
    password: string
  ): Observable<StatusResponse> {
    return this.http.post<StatusResponse>(
      `${this.exportersApiUrl}/${this.commandBgiPlasmaExport}`,
      { blobUri: uri, runId, password }
    );
  }

  halosExport(
    id: string,
    uri: string,
    runId: string
  ): Observable<StatusResponse> {
    return this.http.post<StatusResponse>(
      `${this.exportersApiUrl}/${this.commandHalosExport}`,
      { blobUri: uri, runId }
    );
  }
}

export interface RunFilters {
  ids?: string[];
  excludeIds?: string[];
  nameToken?: string;
  sequencer?: string;
  site?: string;
}

export interface RunHeaders {
  continuationToken?: string;
  select?: string[];
  orderBy?: string;
  orderDirection?: SortDirection;
  pagingTop?: number;
}
