import { Injectable } from '@angular/core';
import { Sample } from '@app/modules/service-data/service-data.types';
import {
  SampleFilters,
  SampleService,
} from '@app/modules/service-data/services/sample.service';
import {
  CreateSampleAction,
  GetSampleAction,
  GetSamplesListAction,
  RemoveSampleAction,
  SAMPLES_NAME,
  SetSamplesAction,
  UpdateSampleAction,
} from '@app/modules/service-data/store/sample.actions';
import { GetServiceDataAction } from '@app/modules/service-data/store/service-data.actions';
import { Pagination, SortDirection, StatePageSettings } from '@core/core.types';
import { Util } from '@core/utils/core.util';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { isEqual } from 'lodash';
import { EMPTY, forkJoin, of } from 'rxjs';
import { catchError, delay, switchMap, tap } from 'rxjs/operators';

export interface SamplesStateModel {
  samples: { [id: string]: Sample };
  samplesByKitIds: { [id: string]: string };
  pages?: {
    [key: string]: {
      items: string[];
      continuationToken: string;
    };
  };
  currentPageSettings: StatePageSettings<SampleFilters>;
  totalCount?: number;
}

const SAMPLES_DEFAULT_STATE: SamplesStateModel = {
  samples: {},
  samplesByKitIds: {},
  pages: {},
  currentPageSettings: {
    pageIndex: 0,
    sortSettings: {
      orderBy: 'Generated',
      sortDirection: SortDirection.desc,
      pageSize: 30,
    },
  },
};

@State<SamplesStateModel>({
  name: SAMPLES_NAME,
  defaults: { ...SAMPLES_DEFAULT_STATE },
})
@Injectable()
export class SampleState {
  constructor(private sampleService: SampleService) {}

  @Selector()
  static getSampleById({ samples }: SamplesStateModel) {
    return (sampleId: string) => samples[sampleId];
  }

  @Selector()
  static getSampleByKitId({ samples, samplesByKitIds }: SamplesStateModel) {
    return (kitId: string) => samples[samplesByKitIds[kitId]];
  }

  @Selector()
  static getSamplesByIds({ samples }: SamplesStateModel) {
    return (sampleIds: string[]) =>
      sampleIds.map((id) => samples[id]).filter((item) => !!item);
  }

  @Selector()
  static getSamplesByKitIds({ samples, samplesByKitIds }: SamplesStateModel) {
    return (kitIds: string[]) =>
      kitIds.map((id) => samples[samplesByKitIds[id]]).filter((item) => !!item);
  }

  @Selector([SampleState.getCurrentPageIds])
  static getCurrentPage(state: SamplesStateModel, ids: string[]) {
    return ids.map((id) => state.samples[id]).filter((sample) => !!sample);
  }

  @Selector()
  static getCurrentPageIds(state: SamplesStateModel) {
    const {
      currentPageSettings: { pageIndex },
      pages,
    } = state;

    if (
      Object.keys(pages).length === 0 ||
      pageIndex > Object.keys(pages).length ||
      pageIndex < 0
    ) {
      return [];
    }
    return state.pages[pageIndex].items.slice();
  }

  @Selector()
  static getPagination({
    currentPageSettings,
    totalCount,
    pages,
  }: SamplesStateModel): Pagination<SampleFilters> {
    return {
      currentPageSettings: {
        ...currentPageSettings,
      },
      totalCount,
      pagesSize: Object.keys(pages).length,
      collectionSize: currentPageSettings.sortSettings.pageSize,
    };
  }

  @Action(GetSamplesListAction)
  getSamplesListAction(
    { patchState, getState, dispatch }: StateContext<SamplesStateModel>,
    { filters, headers, pageToLoad = 1, reload = false }: GetSamplesListAction
  ) {
    // Appends objects are added, in case of separate state @Action calls, as they would otherwise override each other!
    const sampleAppends = {};
    const sampleByKitIdsAppends = {};

    const state = getState();
    const samplesList = { ...state.samples };
    let pages = { ...state.pages };
    const currentPageSettings = {
      ...state.currentPageSettings,
      sortSettings: { ...state.currentPageSettings.sortSettings },
      filterSettings: { ...state.currentPageSettings.filterSettings },
    };
    let totalCount = state.totalCount;

    let actualPageToLoad = pageToLoad ? pageToLoad - 1 : undefined;

    // Update pagination in case of listing pages.
    if (actualPageToLoad !== undefined) {
      currentPageSettings.pageIndex = actualPageToLoad;

      // Check that filters are same as they were for previous page.
      if (
        !isEqual(currentPageSettings.filterSettings, filters) ||
        currentPageSettings.pageSize !== headers.pagingTop ||
        currentPageSettings.sortSettings.sortDirection !==
          headers.orderDirection ||
        currentPageSettings.sortSettings.orderBy !== headers.orderBy
      ) {
        // If filters are different as they were -> update filters, reset pages object and go back to page 1.
        currentPageSettings.pageSize = headers.pagingTop;
        currentPageSettings.sortSettings.sortDirection = headers.orderDirection;
        currentPageSettings.sortSettings.orderBy = headers.orderBy;
        currentPageSettings.sortSettings.pageSize = headers.pagingTop;
        currentPageSettings.filterSettings = filters;

        actualPageToLoad = 0;
        reload = true;
        pages = {};
        currentPageSettings.pageIndex = actualPageToLoad;
      }
    }

    // If reload is not required, check if we already hold treatments with selected properties.
    if (filters.ids && !reload) {
      filters.ids = filters.ids.filter((item) => !samplesList[item]);

      // If we filter out all IDs, return early!
      if (!filters.ids.length) {
        return EMPTY;
      }
    }

    if (filters.ids?.length > 100) {
      return forkJoin(
        Util.chunkArray(filters.ids, 100).map((ids) =>
          dispatch(new GetSamplesListAction({ ids }, headers, null, reload))
        )
      );
    }

    // Get selected page continuation token, if not first page
    if (actualPageToLoad > 0) {
      headers.continuationToken =
        pages[actualPageToLoad - 1]?.continuationToken;
    }

    // If not listing page, page doesnt exist or reload is demanded -> call API
    if (actualPageToLoad === undefined || !pages[actualPageToLoad] || reload) {
      return this.sampleService.getSampleList(filters, headers).pipe(
        tap((response) => {
          response.items.forEach((sample) => {
            sampleAppends[sample.id] = sample;
            sampleByKitIdsAppends[sample.kitId] = sample.id;
          });

          // Add/update pages
          if (actualPageToLoad !== undefined) {
            totalCount = response.totalCount;
            pages[actualPageToLoad] = {
              items: response.items.map((sample) => sample.id),
              continuationToken: response.continuationToken,
            };
          }
        }),
        tap(() => {
          // get latest state again as state might be patched in some other @Action
          const oldState = getState();
          patchState({
            samples: { ...oldState.samples, ...sampleAppends },
            samplesByKitIds: {
              ...oldState.samplesByKitIds,
              ...sampleByKitIdsAppends,
            },
            pages,
            totalCount,
            currentPageSettings,
          });
        }),
        catchError((err) => {
          patchState({});
          return of(null);
        })
      );
    } else {
      // If page exists -> update state so pagination is updated.
      patchState({ currentPageSettings });
      return EMPTY;
    }
  }

  @Action(SetSamplesAction)
  setSamplesAction(
    { getState, patchState }: StateContext<SamplesStateModel>,
    { list, prependOnPage }: SetSamplesAction
  ) {
    const pages = { ...getState().pages };

    return patchState({
      samples: {
        ...getState().samples,
        ...list.reduce<{ [id: string]: Sample }>((acc, item) => {
          acc[item.id] = item;
          return acc;
        }, {}),
      },
      samplesByKitIds: {
        ...getState().samplesByKitIds,
        ...list.reduce<{ [id: string]: string }>((acc, item) => {
          acc[item.kitId] = item.id;
          return acc;
        }, {}),
      },
      pages: {
        ...pages,
        ...(pages[0]?.items && prependOnPage
          ? {
              [0]: {
                continuationToken: pages[0].continuationToken,
                items: [...list.map((sample) => sample.id), ...pages[0].items],
              },
            }
          : {}),
      },
    });
  }

  @Action(GetSampleAction)
  getSampleAction(
    { dispatch, getState }: StateContext<SamplesStateModel>,
    { id, prependToPage, reload = true }: GetSampleAction
  ) {
    if (getState().samples[id] && !reload) {
      return;
    }

    return this.sampleService.getSample(id).pipe(
      switchMap((sample) =>
        dispatch(new SetSamplesAction([sample], prependToPage))
      ),
      catchError(() => of(null))
    );
  }

  @Action(CreateSampleAction)
  createSampleAction(
    { dispatch }: StateContext<SamplesStateModel>,
    { sample }: CreateSampleAction
  ) {
    return this.sampleService.createSample(sample).pipe(
      delay(300),
      switchMap(({ id }) => dispatch(new GetSampleAction(id, true, true)))
    );
  }

  @Action(UpdateSampleAction)
  updateSampleAction(
    { dispatch }: StateContext<SamplesStateModel>,
    { sample }: UpdateSampleAction
  ) {
    return this.sampleService.updateSample(sample).pipe(
      delay(300),
      switchMap(() => dispatch(new GetSampleAction(sample.id, false, true)))
    );
  }

  @Action(RemoveSampleAction)
  removeSampleAction(
    { getState, patchState, dispatch }: StateContext<SamplesStateModel>,
    { sampleId }: RemoveSampleAction
  ) {
    const sdid = getState().samples[sampleId].serviceDataId;
    return this.sampleService.removeSample(sampleId).pipe(
      delay(300),
      switchMap(() => dispatch(new GetServiceDataAction(sdid, false, true))),
      tap(() => {
        const samples = { ...getState().samples };
        const samplesByKitIds = { ...getState().samplesByKitIds };

        delete samples[sampleId];
        delete samplesByKitIds[sampleId];

        patchState({ samples, samplesByKitIds });
      })
    );
  }
}
