import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Country } from '../../core.types';
import { CountriesService } from '../../services/countries.service';
import {
  ActivateCountryAction,
  CreateCountryAction,
  DeactivateCountryAction,
  GetCountriesAction,
  GetCountryAction,
  SetCountriesAction,
  UpdateCountryAction,
} from './countries.actions';
import { switchMap } from 'rxjs';
import { delay } from 'rxjs/operators';

export interface CountriesStateModel {
  countries: Country[];
}

@State<CountriesStateModel>({
  name: 'countries',
  defaults: {
    countries: [],
  },
})
@Injectable()
export class CountriesState {
  constructor(private countriesService: CountriesService) {}

  @Selector()
  static getCountries(state: CountriesStateModel) {
    return state.countries;
  }

  @Selector()
  static getCountryById(state: CountriesStateModel) {
    return (id) => state.countries.find((country) => country.id === id);
  }

  @Selector([CountriesState.getCountries])
  static getCountryByName(state: CountriesStateModel, countries: Country[]) {
    return (name: string) => countries.find((country) => country.name === name);
  }

  @Selector([CountriesState.getCountries])
  static getCountryByIso(state: CountriesStateModel, countries) {
    return (iso3) => countries.find((country) => country.isoCode3 === iso3);
  }

  @Selector([CountriesState.getCountryByName])
  static getCountryRegions(state, countryByName) {
    return (name) => countryByName(name).regions;
  }

  @Action(SetCountriesAction)
  setCountries(
    { patchState, getState }: StateContext<CountriesStateModel>,
    { countries }: SetCountriesAction
  ) {
    const filteredCountries = [...getState().countries].filter(
      (country) => !countries.find((newCountry) => newCountry.id === country.id)
    );
    return patchState({
      countries: filteredCountries
        .concat(countries)
        .sort((c1, c2) => c1.name.localeCompare(c2.name)),
    });
  }

  @Action(GetCountriesAction)
  getCountriesList({ dispatch }: StateContext<CountriesStateModel>) {
    return this.countriesService
      .getCountries()
      .pipe(
        switchMap((countries) => dispatch(new SetCountriesAction(countries)))
      );
  }

  @Action(GetCountryAction)
  getCountry(
    { dispatch }: StateContext<CountriesStateModel>,
    { id }: GetCountryAction
  ) {
    return this.countriesService
      .getCountry(id)
      .pipe(
        switchMap((country) => dispatch(new SetCountriesAction([country])))
      );
  }

  @Action(UpdateCountryAction)
  updateCountry(
    { dispatch }: StateContext<CountriesStateModel>,
    { id, country }: UpdateCountryAction
  ) {
    return this.countriesService.updateCountry(id, country).pipe(
      delay(500),
      switchMap(() => dispatch(new GetCountryAction(id)))
    );
  }

  @Action(CreateCountryAction)
  createCountry(
    { dispatch }: StateContext<CountriesStateModel>,
    { country }: CreateCountryAction
  ) {
    return this.countriesService.createCountry(country).pipe(
      delay(500),
      switchMap(({ id }) => dispatch(new GetCountryAction(id)))
    );
  }

  @Action(ActivateCountryAction)
  activateCountry(
    // eslint-disable-next-line no-empty-pattern
    {}: StateContext<CountriesStateModel>,
    { id }: ActivateCountryAction
  ) {
    return this.countriesService.activateCountry(id);
  }

  @Action(DeactivateCountryAction)
  deactivateCountry(
    // eslint-disable-next-line no-empty-pattern
    {}: StateContext<CountriesStateModel>,
    { id }: DeactivateCountryAction
  ) {
    return this.countriesService.deactivateCountry(id);
  }
}
