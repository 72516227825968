import { Injectable } from '@angular/core';
import {
  ALL_KITS_PARTNER_ID,
  MOMENT_DATE_FORMAT_DASH_REVERSED,
  UNASSIGNED_PARTNER_ID,
} from '@app/app.constants';
import { updateExpiredStatus } from '@app/modules/kits/kits.utils';
import { Util } from '@core/utils/core.util';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import {
  KITS_PAGE_SORT_SETTINGS,
  STATUSES,
} from '@shared/constants/kit.constants';
import moment from 'moment';
import { Observable, forkJoin, from, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import {
  Kit,
  NO_ID_SELECTED,
  Pagination,
  StatePageSettings,
} from '../../core.types';
import { AuthenticationService } from '../../services/authentication.service';
import { ExportersService } from '../../services/exporters.service';
import { KitsService } from '../../services/kits.service';
import { Logger } from '../../services/logger.service';
import {
  AddKitsToPartnerAction,
  AssignPartnerToKits,
  ClearKitsStateAction,
  DeleteKitsAction,
  LoadAllPartnerKitsAction,
  LoadKitById,
  LoadKitsByIdAction,
  LoadPartnerFreeKitsAction,
  LoadPartnerKitsAction,
  LoadPartnersFreeKitsAction,
  LoadPartnersFreeKitsCountAction,
  SetKitStateReturnedAction,
  SetKitsById,
  UnassignProductInventoryItem,
  UpdateKitsAction,
} from './kits.actions';

const KITS_STATE_NAME = 'kits';

export interface KitsListFilterSettings {
  partnerId?: string;
  barcode?: string;
  multipleBarcodes?: string;
  status?: string;
  statusUpdate?: string;
  expiresDate?: string;
  laboratory?: string;
  country?: string;
  notes?: string;
  selectedRuns?: {
    runsIds: string[];
    kitsIds: string[];
  };
}

export interface KitsStateModel {
  kits: { [id: string]: Kit };
  pages?: {
    items: string[];
    continuationToken: string;
  }[];
  currentPageSettings: StatePageSettings<KitsListFilterSettings>;
  totalCount?: number;
  partnerId?: string;
  partnerKits: { [id: string]: Kit[] };
  partnerFreeKits: { [id: string]: Kit[] };
  partnerFreeKitsCount: { [id: string]: number };
}

const KITS_DEFAULT_STATE: KitsStateModel = {
  kits: {},
  pages: [],
  currentPageSettings: {
    pageIndex: 0,
    pageSize: 30,
    sortSettings: {
      orderBy: KITS_PAGE_SORT_SETTINGS.orderBy,
      sortDirection: KITS_PAGE_SORT_SETTINGS.sortDirection,
    },
    filterSettings: {
      partnerId: '',
      barcode: '',
      multipleBarcodes: '',
      status: '',
      statusUpdate: '',
      expiresDate: '',
      laboratory: '',
      selectedRuns: {
        runsIds: [],
        kitsIds: [],
      },
    },
  },
  totalCount: 0,
  partnerId: NO_ID_SELECTED,
  partnerFreeKits: {},
  partnerFreeKitsCount: {},
  partnerKits: {},
};

@State<KitsStateModel>({
  name: KITS_STATE_NAME,
  defaults: {
    ...KITS_DEFAULT_STATE,
  },
})
@Injectable()
export class KitsState {
  private readonly log = new Logger(this.constructor.name);

  constructor(
    protected kitsService: KitsService,
    protected exportersService: ExportersService,
    protected store: Store,
    private authenticationService: AuthenticationService
  ) {}

  @Selector()
  static getKitsState(state: KitsStateModel) {
    return state.pages;
  }

  @Selector()
  static getPageState(state: KitsStateModel) {
    return state;
  }

  @Selector()
  static getKitsPage(state: KitsStateModel) {
    return (page: number) => {
      if (state.pages[page]) {
        return state.pages[page];
      }
    };
  }

  @Selector()
  static getPartnerKits({ kits }: KitsStateModel) {
    return (partnerId: string) =>
      Object.keys(kits)
        .map((kitId) =>
          kits[kitId] && kits[kitId].partnerId === partnerId
            ? kits[kitId]
            : null
        )
        .filter((k) => k !== null);
  }

  @Selector()
  static getNumberOfLoadedPages$(state: KitsStateModel) {
    return Object.keys(state.pages).map((x) => x);
  }

  @Selector()
  static getKitById({ kits: kitsState }: KitsStateModel) {
    return (kitId: string) => kitsState[kitId];
  }

  @Selector()
  static getKitsByIds({ kits: kitsState }: KitsStateModel) {
    return (kitIds: string[]) =>
      kitIds.map((kitId) => kitsState[kitId]).filter((kit) => !!kit);
  }

  @Selector()
  static getPartnerKitsNew({ partnerKits }: KitsStateModel) {
    return (partnerId: string) => partnerKits[partnerId];
  }

  @Selector()
  static getFreePartnerKits({ partnerFreeKits }: KitsStateModel) {
    return (partnerId: string) => partnerFreeKits[partnerId] ?? [];
  }

  @Selector()
  static getFreePartnerKitsCount({ partnerFreeKitsCount }: KitsStateModel) {
    return (partnerId: string) => partnerFreeKitsCount[partnerId];
  }

  @Selector()
  static getCurrentPage(state: KitsStateModel) {
    const {
      currentPageSettings: { pageIndex },
      pages,
    } = state;

    if (pages.length === 0 || pageIndex > pages.length || pageIndex < 0) {
      return [];
    }
    return state.pages[pageIndex].items;
  }

  @Selector()
  static getPaginationInfo({
    currentPageSettings,
    totalCount,
    pages,
  }: // eslint-disable-next-line @typescript-eslint/ban-types
  KitsStateModel): Pagination<{}> {
    return {
      currentPageSettings: {
        ...currentPageSettings,
      },
      totalCount,
      pagesSize: pages.length,
      collectionSize: currentPageSettings.pageSize,
    };
  }

  @Action(ClearKitsStateAction)
  clearKitsState({ patchState }: StateContext<KitsStateModel>) {
    this.log.debug('ClearKitsStateAction');
    const { pages, currentPageSettings, totalCount, partnerId } =
      KITS_DEFAULT_STATE;
    patchState({
      pages,
      currentPageSettings,
      totalCount,
      partnerId,
    });
  }

  @Action(LoadPartnerKitsAction)
  loadPartnerKitsAction(
    { getState, patchState, dispatch }: StateContext<KitsStateModel>,
    {
      options: { pageToLoad, orderDirection, orderBy, filter, pageSize },
      refresh,
    }: LoadPartnerKitsAction
  ) {
    this.log.debug('LoadPartnerKitsAction');
    pageToLoad = pageToLoad || null;
    filter = filter || { partnerId: '' };

    const {
      currentPageSettings: { sortSettings, filterSettings },
      pages,
    } = getState();
    let isReloadRequired = refresh;

    if (orderBy) {
      if (orderBy !== sortSettings.orderBy) {
        isReloadRequired = true;
      }
    } else {
      orderBy = sortSettings.orderBy;
    }
    if (orderDirection) {
      if (orderDirection !== sortSettings.sortDirection) {
        isReloadRequired = true;
      }
    } else {
      orderDirection = sortSettings.sortDirection;
    }

    let newSelectedRunsFilter = '';
    const oldSelectedRunsFilter = filterSettings.selectedRuns
      ? filterSettings.selectedRuns.kitsIds.join(',')
      : '';
    if (filter.selectedRuns) {
      if (filter.selectedRuns.kitsIds.length > 500) {
        filter.selectedRuns.kitsIds.length = 500;
      }
      newSelectedRunsFilter = filter.selectedRuns.kitsIds.join(',');
    } else {
      newSelectedRunsFilter = oldSelectedRunsFilter;
    }
    if (
      newSelectedRunsFilter !== oldSelectedRunsFilter ||
      getState().currentPageSettings.pageSize !== pageSize
    ) {
      isReloadRequired = true;
    }

    if (
      filter &&
      Object.keys(filterSettings).some((filterKey) =>
        filterKey !== 'selectedRuns' &&
        Object.prototype.hasOwnProperty.call(filter, filterKey)
          ? filterSettings[filterKey] !== filter[filterKey]
          : false
      )
    ) {
      isReloadRequired = true;
    }

    const mapKey = (key: string) => {
      switch (key) {
        case 'partnerId':
          return 'PartnerId';
        case 'barcode':
          return 'BarcodeToken';
        case 'multipleBarcodes':
          return 'Ids';
        case 'country':
          return 'PartnerAddressCountryTokens';
        case 'statusUpdate':
          return 'LastStatusChanged';
        case 'expiresDate':
          return 'Expires';
        case 'laboratory':
          return 'Laboratory';
        case 'notes':
          return 'Notes';
        case 'selectedRuns':
        case 'status':
          return null;
        default:
          return new Error('Unknown key');
      }
    };

    const filters = new Map<string, string>(
      Object.keys(filter)
        .map((filterKey) =>
          filter[filterKey] && mapKey(filterKey)
            ? [mapKey(filterKey), filter[filterKey]]
            : null
        )
        .filter((f) => f !== null) as [string, string][]
    );

    const status = filter.status;
    if (filter.status) {
      const today = moment().startOf('day').toISOString();
      switch (filter.status) {
        case 'Claimed':
        case 'Returned':
          filters.set('lastStatus', filter.status);
          break;
        case 'Generated':
        case 'Shipped':
          filters.set('lastStatus', filter.status);
          filters.set('expiresFrom', today);
          break;
        case 'Expired':
          filters.set('lastStatuses', 'Generated,Shipped');
          filters.set('expiresTo', today);
          break;
      }
      delete filter.status;
    }

    const clearOrSkip$: Observable<any> = isReloadRequired
      ? dispatch(new ClearKitsStateAction())
      : of(null);

    let continuationToken = null;
    if (pageToLoad !== null) {
      continuationToken = pages[pageToLoad - 2]?.continuationToken;

      if (!isReloadRequired) {
        if (pages.length >= pageToLoad) {
          return of(
            patchState({
              currentPageSettings: {
                ...getState().currentPageSettings,
                pageIndex: pageToLoad - 1,
              },
            })
          );
        }
      }
    }

    let loadedKits$: Observable<any>;
    if (newSelectedRunsFilter) {
      filters.set('ids', newSelectedRunsFilter);
    }
    if (filters.has('LastStatusChanged')) {
      const statusChangedFilterDate = moment(filters.get('LastStatusChanged'));
      filters.set(
        'LastStatusChangedFrom',
        `${statusChangedFilterDate.format(
          MOMENT_DATE_FORMAT_DASH_REVERSED
        )}T00:00:00`
      );
      filters.set(
        'LastStatusChangedTo',
        `${statusChangedFilterDate.format(
          MOMENT_DATE_FORMAT_DASH_REVERSED
        )}T23:59:59`
      );
      filters.delete('LastStatusChanged');
    }
    if (filter.partnerId === UNASSIGNED_PARTNER_ID) {
      loadedKits$ = this.kitsService.getUnAssignedKits({
        top: pageSize,
        continuationToken,
        orderBy,
        orderDirection,
      });
    } else if (filter.partnerId && filter.partnerId !== ALL_KITS_PARTNER_ID) {
      loadedKits$ = this.kitsService.getPartnerKits({
        top: pageSize,
        continuationToken,
        filters,
        orderBy,
        orderDirection,
      });
    } else {
      filters.delete('PartnerId');
      loadedKits$ = this.kitsService.getAllKits({
        top: pageSize,
        continuationToken,
        filters,
        orderBy,
        orderDirection,
      });
    }
    clearOrSkip$
      .pipe(
        switchMap(() =>
          loadedKits$.pipe(
            tap(
              ({
                entitiesList,
                newContinuationToken,
                totalCount,
              }: {
                entitiesList;
                totalCount;
                newContinuationToken;
              }) => {
                const kits = { ...getState().kits };
                for (const kit of entitiesList) {
                  kits[kit.id] = kit;
                }
                patchState({
                  currentPageSettings: {
                    sortSettings: {
                      pageSize,
                      sortDirection: orderDirection,
                      orderBy,
                    },
                    pageSize,
                    filterSettings: {
                      partnerId: filter.partnerId,
                      barcode: filter.barcode,
                      status,
                      statusUpdate: filter.statusUpdate,
                      expiresDate: filter.expiresDate,
                      laboratory: filter.laboratory,
                      selectedRuns: filter.selectedRuns,
                      notes: filter.notes,
                    },
                    pageIndex: pageToLoad !== null ? pageToLoad - 1 : 0,
                  },
                  totalCount,
                  pages: [
                    ...getState().pages,
                    {
                      items: entitiesList.map((t) => t.id),
                      continuationToken: newContinuationToken,
                    },
                  ],
                  kits: updateExpiredStatus(kits),
                });
              }
            )
          )
        )
      )
      .subscribe();
  }

  @Action(LoadPartnerFreeKitsAction)
  loadPartnerFreeKitsAction(
    { getState, patchState }: StateContext<KitsStateModel>,
    action: LoadPartnerFreeKitsAction
  ) {
    const partnerId = action.partnerId;
    return this.kitsService.getPartnerFreeKits(partnerId).pipe(
      switchMap((partnerFreeKits: Kit[]) => {
        const kits = {
          ...getState().kits,
        };
        for (const kit of partnerFreeKits) {
          kits[kit.id] = kit;
        }
        return of(
          patchState({
            kits: updateExpiredStatus(kits),
            partnerFreeKits: {
              ...getState().partnerFreeKits,
              [partnerId]: partnerFreeKits,
            },
          })
        );
      })
    );
  }

  @Action(LoadPartnersFreeKitsAction)
  loadPartnersFreeKitsAction(
    { getState, patchState }: StateContext<KitsStateModel>,
    action: LoadPartnersFreeKitsAction
  ) {
    // From promise to observable so we can use pipes
    return from(
      this.kitsService.getPartnersFreeKits(action.partnerIds.join(','))
    ).pipe(
      switchMap((freeKits: Kit[]) => {
        const kits = {
          ...getState().kits,
        };
        const partnerFreeKits = {};
        for (const kit of freeKits) {
          kits[kit.id] = kit;
          if (!partnerFreeKits[kit.partnerId]) {
            partnerFreeKits[kit.partnerId] = [];
          }
          partnerFreeKits[kit.partnerId].push(kit);
        }
        return of(
          patchState({
            kits: updateExpiredStatus(kits),
            partnerFreeKits: {
              ...getState().partnerFreeKits,
              ...partnerFreeKits,
            },
          })
        );
      })
    );
  }

  @Action(LoadPartnersFreeKitsCountAction)
  loadPartnersFreeKitsCountAction(
    { getState, patchState }: StateContext<KitsStateModel>,
    { partnerIds }: LoadPartnersFreeKitsCountAction
  ) {
    return this.kitsService.getPartnersFreeKitsCount(partnerIds).pipe(
      tap((partners) => {
        patchState({
          partnerFreeKitsCount: {
            ...getState().partnerFreeKitsCount,
            ...partners.reduce((acc, partner) => {
              acc[partner.partnerId] = partner.freeKits;
              return acc;
            }, {}),
          },
        });
      })
    );
  }

  @Action(AddKitsToPartnerAction)
  addKitsToPartner(
    { getState, patchState, dispatch }: StateContext<KitsStateModel>,
    { payload: { kitsList } }: AddKitsToPartnerAction
  ) {
    const createRequests$ = kitsList.map((kit) =>
      this.kitsService.createNewEntity(kit).pipe(
        catchError((err) => of(err.status)),
        map((error) => ({ kit, error })),
        switchMap(({ error }) => {
          if (kit && !error) {
            const userId = this.authenticationService.getUserId();
            kit.lastStatusChanged = new Date();
            kit.generated = { by: userId, timestamp: new Date() };
            if (kit.lastStatus === STATUSES.shipped) {
              return this.kitsService.markKitAsShipped(kit.id, new Date()).pipe(
                map(() => ({
                  completedKit: {
                    ...kit,
                    shipped: { by: userId, timestamp: new Date() },
                  },
                }))
              );
            }
          }

          return of({
            completedKit: {
              ...kit,
            },
          });
        })
      )
    );

    return forkJoin(createRequests$).pipe(
      map(
        (kits) =>
          kits
            .map((kit: any) => {
              if (
                Object.prototype.hasOwnProperty.call(kit, 'completedKit') &&
                kit.completedKit
              ) {
                kit.completedKit.productInventoryItemIds = [];
                return kit.completedKit;
              }
              return null;
            })
            .filter((k) => k !== null) as Kit[]
      ),
      tap((kits) => {
        const {
          pages,
          currentPageSettings: { pageIndex },
        } = getState();
        const page = pages[pageIndex];
        const { items } = page;
        const newPages = [...pages];
        newPages[pageIndex] = {
          ...page,
          items: [...kits.map((k) => k.id), ...items],
        };
        patchState({
          kits: updateExpiredStatus({
            ...getState().kits,
            ...kits.reduce((acc, kit) => {
              acc[kit.id] = kit;
              return acc;
            }, {}),
          }),
          pages: newPages,
        });
      })
    );
  }

  @Action(UpdateKitsAction)
  updateKits(
    { dispatch }: StateContext<KitsStateModel>,
    { payload: { kitsList } }: UpdateKitsAction
  ) {
    const updates$ = kitsList.map((kit) =>
      this.kitsService.updateEntity(kit).pipe(
        catchError((err) => of(err.status)),
        map((error) => {
          kit.lastStatusChanged = new Date();
          return { kit, error };
        })
      )
    );

    forkJoin(updates$)
      .pipe(switchMap(() => dispatch(new SetKitsById(kitsList))))
      .subscribe();
  }

  @Action(SetKitStateReturnedAction)
  async setKitStateReturned(
    { getState, patchState }: StateContext<KitsStateModel>,
    { payload: { kitId, date } }: SetKitStateReturnedAction
  ) {
    this.log.debug(`SetKitStateReturnedAction`, kitId);
    const kit = getState().kits[kitId];
    if (kit && kit.returned) {
      this.log.debug(`kit ${kitId} already returned`);
      return;
    }
    try {
      await this.kitsService.markKitAsReturned(kitId, date).toPromise();
      const kits = getState().kits;
      if (kits[kitId]) {
        const returned = { timestamp: date, by: '' };
        const updatedKit: Kit = {
          ...kits[kitId],
          returned,
          lastStatus: STATUSES.returned,
          lastStatusChanged: returned.timestamp,
          lastStatusChangedBy: returned.by,
        };
        patchState({
          kits: updateExpiredStatus({ ...kits, [kitId]: updatedKit }),
        });
      }
      // eslint-disable-next-line no-empty
    } catch (e) {}
  }

  @Action(AssignPartnerToKits)
  assignPartnerToKits(
    { getState, patchState, dispatch }: StateContext<KitsStateModel>,
    { payload: { kitsList, partnerId } }: AssignPartnerToKits
  ) {
    this.log.debug('AssignPartnerToKits');

    kitsList = kitsList.map((kit) => ({ ...kit, partnerId }));

    const assignRequests = kitsList.map((kit) =>
      this.kitsService.assignPartnerToKit(kit.id, partnerId)
    );

    const notShippedKits = kitsList.filter((k) => !k.shipped);
    const markAsShippedRequests = notShippedKits.map((kit) =>
      this.kitsService.markKitAsShipped(kit.id, new Date())
    );

    return forkJoin([...assignRequests, ...markAsShippedRequests])
      .pipe(
        switchMap(() => dispatch(new SetKitsById(kitsList))),
        map(() => {
          const kitIdsToInsert: string[] = [];
          kitsList.forEach((kit) => {
            const isOnPage = !!getState().pages.find((p) =>
              p.items.includes(kit.id)
            );
            if (!isOnPage) {
              kitIdsToInsert.push(kit.id);
            }
          });
          if (kitIdsToInsert.length) {
            const {
              pages,
              currentPageSettings: { pageIndex },
            } = getState();

            const newPages = [...pages];

            if (pages && pages.length > pageIndex) {
              const page = pages[pageIndex];
              const { items } = page;
              newPages[pageIndex] = {
                ...page,
                items: [...kitIdsToInsert, ...items],
              };

              return patchState({
                pages: newPages,
              });
            }
            return null;
          }
        })
      )
      .subscribe();
  }

  @Action(DeleteKitsAction)
  deleteKits(
    { getState, patchState }: StateContext<KitsStateModel>,
    { payload: { kitsList } }: DeleteKitsAction
  ) {
    const deleteRequests$ = kitsList.map((kit) =>
      this.kitsService.deleteEntity(kit.id)
    );

    forkJoin([...deleteRequests$])
      .pipe(
        tap(() => {
          const { pages } = getState();
          const kitListIds = kitsList.map((k) => k.id);
          const pagesWithoutDeletedKits = pages.map((page) => {
            const { items } = page;
            return {
              ...page,
              items: items.filter((item) => !kitListIds.includes(item)),
            };
          });
          patchState({
            pages: pagesWithoutDeletedKits,
          });
        })
      )
      .subscribe();
  }

  @Action(LoadKitById)
  loadKitById(
    { getState, patchState }: StateContext<KitsStateModel>,
    { kitId, refresh }: LoadKitById
  ) {
    this.log.debug('LoadKitById');
    if (kitId) {
      const { kits } = getState();
      if (refresh || !Object.prototype.hasOwnProperty.call(kits, kitId)) {
        if (!Object.prototype.hasOwnProperty.call(kits, kitId)) {
          patchState({
            kits: { ...kits, [kitId]: undefined },
          });
        }
        return this.kitsService.getKit(kitId).pipe(
          switchMap((kit: Kit) =>
            of(
              patchState({
                kits: updateExpiredStatus({
                  ...getState().kits,
                  [kitId]: kit || null,
                }),
              })
            )
          )
        );
      }
    }
    return of(null);
  }

  @Action(LoadKitsByIdAction)
  loadKitsById(
    { getState, patchState }: StateContext<KitsStateModel>,
    { kitsIds, refresh }: LoadKitsByIdAction
  ) {
    this.log.debug('LoadKitsByIdAction');
    if (kitsIds && kitsIds.length) {
      const { kits } = getState();
      const kitsToLoad = kitsIds.filter(
        (kitId) => refresh || !Object.prototype.hasOwnProperty.call(kits, kitId)
      );
      if (kitsToLoad.length) {
        const patchedKits = { ...kits };
        for (const kitId of kitsToLoad) {
          if (!Object.prototype.hasOwnProperty.call(kits, kitId)) {
            patchedKits[kitId] = null;
          }
        }
        patchState({
          kits: updateExpiredStatus(patchedKits),
        });

        return forkJoin(
          Util.chunkArray(Util.removeDuplicates(kitsToLoad), 200).map((chunk) =>
            this.kitsService.getKits(chunk).pipe(
              switchMap((loadedKits: Kit[]) => {
                const patchedKitsState = { ...getState().kits };
                for (const kit of loadedKits) {
                  patchedKitsState[kit.id] = kit;
                }
                return of(
                  patchState({
                    kits: updateExpiredStatus(patchedKitsState),
                  })
                );
              })
            )
          )
        );
      }
    }
    return of(null);
  }

  @Action(LoadAllPartnerKitsAction)
  loadAllPartnerKits(
    { getState, patchState }: StateContext<KitsStateModel>,
    { partnerId, cache }: LoadAllPartnerKitsAction
  ) {
    this.log.debug('LoadAllPartnerKitsAction');
    if (
      !Object.prototype.hasOwnProperty.call(
        getState().partnerKits,
        partnerId
      ) ||
      cache
    ) {
      patchState({
        partnerKits: {
          ...getState().partnerKits,
          [partnerId]: [],
        },
      });
      return from(this.kitsService.getAllPartnerKits(partnerId)).pipe(
        switchMap((entitiesList) => {
          const partnerKits = {
            ...getState().partnerKits,
            [partnerId]: entitiesList,
          };
          const kits = {
            ...getState().kits,
          };
          for (const kit of entitiesList) {
            kits[kit.id] = kit;
          }
          return of(
            patchState({
              kits: updateExpiredStatus(kits),
              partnerKits,
            })
          );
        })
      );
    }
    return of(null);
  }

  @Action(SetKitsById)
  setKitsById(
    { getState, patchState }: StateContext<KitsStateModel>,
    { kits }: SetKitsById
  ) {
    this.log.debug('SetKitsById');
    const kitsArray: Kit[] = Array.isArray(kits) ? kits : [kits];
    if (kitsArray.length) {
      const kitsObject = kitsArray.reduce((acc, cur) => {
        acc[cur.id] = cur;
        return acc;
      }, {});
      return of(
        patchState({
          kits: updateExpiredStatus({
            ...getState().kits,
            ...kitsObject,
          }),
        })
      );
    }
    return of(null);
  }

  @Action(UnassignProductInventoryItem)
  unassignProductInventoryItem(
    { getState, patchState }: StateContext<KitsStateModel>,
    { kitId, productInventoryId }: UnassignProductInventoryItem
  ) {
    return this.kitsService.unassignProductInventoryItem(
      kitId,
      productInventoryId
    );
  }
}
