import { Injectable } from '@angular/core';
import { Partner } from '@core/core.types';
import { Logger } from '@core/services/logger.service';
import { ProductUtilsService } from '@core/services/product-utils.service';
import { LoadBundleConfigurationByIds } from '@core/store/bundle-configuration/bundle-configuration.actions';
import { BundleConfigurationsState } from '@core/store/bundle-configuration/bundle-configuration.state';
import { NbToastrService } from '@nebular/theme';
import { Select, Store } from '@ngxs/store';
import { Observable, combineLatest, of } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  map,
  switchMap,
  take,
} from 'rxjs/operators';
import { NOTIFICATION_EMAILS_CONSTANTS } from '../../app.constants';
import { Country } from '../@core/core.types';
import { AccountUtilsService } from '../@core/services/account-utils.service';
import { PartnersService } from '../@core/services/partners.service';
import { CountriesState } from '../@core/store/countries/countries.state';
import { PartnerTypeState } from '../@core/store/partner-types/partner-types.state';
import { LoadPartnerById } from '../@core/store/partners/partners.actions';
import { PartnersState } from '../@core/store/partners/partners.state';
import { ProductsListState } from '../@core/store/products/products.state';

@Injectable({
  providedIn: 'root',
})
export class PartnersUtilsService {
  @Select(CountriesState.getCountries) countries$: Observable<Country[]>;
  private readonly log = new Logger(this.constructor.name);

  constructor(
    private store: Store,
    private partnersService: PartnersService,
    private productUtils: ProductUtilsService,
    private accountUtils: AccountUtilsService,
    private toastrService: NbToastrService
  ) {}

  getPartnerProducts$(partner: Partner): Observable<any[]> {
    this.log.debug('get partner products');
    if (!partner || !partner.productConfigurations) {
      return of(null);
    }
    if (!partner.productConfigurations.length) {
      return of([]);
    }
    const productsConfigurations$ = combineLatest(
      partner.productConfigurations.map((pcId) =>
        this.productUtils.getProductConfigurationById$(pcId)
      )
    );
    return combineLatest([
      this.store.select(ProductsListState.getProductsList),
      productsConfigurations$,
    ]).pipe(
      map(([products, productsConfigurations]) =>
        productsConfigurations.map((pc) =>
          pc && pc.productId
            ? products.find((p) => p.id === pc.productId)
            : null
        )
      )
    );
  }

  getPartnerById$(partnerId: string, refresh = false): Observable<Partner> {
    this.log.debug('get partner by id');

    if (!partnerId) {
      return of(null);
    }

    return this.store.dispatch(new LoadPartnerById(partnerId, refresh)).pipe(
      switchMap(() =>
        this.store.select(PartnersState.getPartnerById).pipe(
          map((filterById: (id: string) => Partner) => filterById(partnerId)),
          filter((p) => p !== undefined)
        )
      ),
      distinctUntilChanged()
    );
  }

  getSubPartnerById$(
    subPartnerId: string,
    refresh = false
  ): Observable<Partner> {
    return this.store.dispatch(new LoadPartnerById(subPartnerId, refresh)).pipe(
      switchMap(() =>
        this.store.select(PartnersState.getPartnerById).pipe(
          map((filterById) => filterById(subPartnerId)),
          filter((d) => d !== undefined)
        )
      ),
      distinctUntilChanged()
    );
  }

  getPartnersBySearchString(searchString: string): Observable<Partner[]> {
    this.log.debug('get partners by search string');
    return combineLatest([
      this.store.select(PartnerTypeState.getFirstLevelPartnerTypesIdsList),
      this.accountUtils.getRoleCountries$(),
    ]).pipe(
      take(1),
      switchMap(([ids, roleCountries]) =>
        this.partnersService.findPartners(ids, searchString, roleCountries)
      )
    );
  }

  filterPartnerCountries$(): Observable<Country[]> {
    return combineLatest([
      this.accountUtils.getRoleCountries$(),
      this.countries$,
    ]).pipe(
      map(([roleCountries, countries]) => {
        if (roleCountries) {
          countries = countries.filter(
            (c) => roleCountries.indexOf(c.name.toLowerCase()) > -1
          );
        }
        return countries;
      })
    );
  }

  getDoctorsIdsBySearchToken(
    doctorNameToken: string,
    limit = false
  ): Observable<any> {
    this.log.debug('get doctors ids by search token');
    if (doctorNameToken && doctorNameToken.length > 0) {
      return this.partnersService
        .getPartnersByNametoken(doctorNameToken.trim())
        .pipe(
          take(200),
          switchMap((doctors) => {
            if (doctors && doctors.length > 0) {
              if (doctors.length <= 40 || !limit) {
                return of(doctors.slice(0, 300).map((d) => d.id));
              } else {
                this.toastrService.danger(
                  'Too many doctors, please filter more precisely',
                  'Filter error'
                );
                return of(null);
              }
            }
            return of(null);
          })
        );
    }
    return of(null);
  }

  getResultReferenceLinkTemplateByLanguage(lang: string) {
    switch (lang.toLowerCase()) {
      case 'slovenian':
        return NOTIFICATION_EMAILS_CONSTANTS.resultReferenceLinkSl;
      case 'croatian':
        return NOTIFICATION_EMAILS_CONSTANTS.resultReferenceLinkHr;
      case 'polish':
        return NOTIFICATION_EMAILS_CONSTANTS.resultReferenceLinkPl;
      default:
        return NOTIFICATION_EMAILS_CONSTANTS.resultReferenceLinkEn;
    }
  }

  getPatientFormLinkTemplateByLanguage(lang: string) {
    switch (lang.toLowerCase()) {
      case 'spanish':
        return NOTIFICATION_EMAILS_CONSTANTS.customerFormInviteMx;
      default:
        return NOTIFICATION_EMAILS_CONSTANTS.customerFormInvitePl;
    }
  }

  public getPartnerBundles(bundleConfigurationIds: string[]) {
    return this.getPartnerBundleIds(bundleConfigurationIds).pipe(
      switchMap((bundleIds: string[]) =>
        this.store
          .select(ProductsListState.getBundles)
          .pipe(map((getBundles) => getBundles(bundleIds)))
      )
    );
  }

  private getPartnerBundleIds(
    bundleConfigurationIds: string[]
  ): Observable<string[]> {
    return this.store
      .dispatch(new LoadBundleConfigurationByIds(bundleConfigurationIds))
      .pipe(
        switchMap(() =>
          this.store
            .select(BundleConfigurationsState.getBundleConfigurationByIds)
            .pipe(
              map((getBundleConfigurationByIds) => {
                return getBundleConfigurationByIds(bundleConfigurationIds).map(
                  (bundleConfiguration) => bundleConfiguration.bundleId
                );
              })
            )
        )
      );
  }
}
